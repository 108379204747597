import { atom } from "recoil";
import { EncryptionStorage } from "@unisearch/helpers";

const systemAtom = atom({
  key: "system",
  default: {
    loading: false,
    returnUrl:
      typeof window !== "undefined"
        ? JSON.parse(EncryptionStorage.getItem("returnUrl"))
        : false,
    currency:
      typeof window !== "undefined"
        ? JSON.parse(EncryptionStorage.getItem("syscur"))
        : "",
    visitor_country: {},
  },
});

export { systemAtom };
