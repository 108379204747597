import { uniMsgAtom } from "@_recoil/_state";
import { useSetRecoilState } from "recoil";

export function useUniMsgActions() {
  const setArrivalMsg = useSetRecoilState(uniMsgAtom);

  return {
    getArrivalMsg,
  };

  // get auth profile data
  async function getArrivalMsg(data) {
    setArrivalMsg(data);
  }
}

export default useUniMsgActions;
