const { createSlice } = require("@reduxjs/toolkit");

const eventPopupSlice = createSlice({
  name: "eventPopup",
  initialState: {
    isOpen: true,
  },

  reducers: {
    handleIsOpen: (state, action) => {
      state.isOpen = action.payload.isOpen;
    },
  },
});

export const { handleIsOpen } = eventPopupSlice.actions;
export default eventPopupSlice.reducer;
