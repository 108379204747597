import { atom } from "recoil";



export const vPopup = atom({
  key: "vpopup",
  default: {
    handleVPopup: () => {
      document.getElementById("vPopup").scrollIntoView({ behavior: "smooth" })
    },
  },
});



