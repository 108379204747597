import { sanitize } from "isomorphic-dompurify";
import parse from "html-react-parser";
import { ReactSVG } from "react-svg";

//public url
export const public_url = (url = null) => {
  let return_url = process.env.NEXT_PUBLIC_URL || "https://myunisearch.com";
  if (url) return return_url + url;
  return return_url;
};

//upper case First Letter
export const upperFirstLetter = (str) => {
  return str?.charAt(0).toUpperCase() + str?.slice(1);
};

//export title case letter
export const toTitleCase = (str) => {
  if (!str) return false;

  return str.replace(/(^\w|\s\w)/g, (m) => m.toUpperCase());
};

// country name
export const countryNameHelper = (countryInfo) => {
  if (!countryInfo) return;
  const arr = ["united-states", "united-kingdom"];
  let name = "";
  if (arr.includes(countryInfo?.slug)) {
    name = `The ${countryInfo?.code == "GBR" ? "UK" : countryInfo?.code}`;
  } else {
    name = countryInfo?.name;
  }
  return name;
};

//remove tags
export const remove_tags = (_html) => {
  let html = _html.toString();
  const text = html.replace(/(<([^>]+)>)/gi, "");
  return text.replace(/&nbsp;/g, " ");
};

//printe excerpt
export const excerpt = (_html, count = 100) => {
  const text = remove_tags(_html);
  return text.slice(0, count) + (text.length > count ? "..." : "");
};
export const excerptWithRemoveQuote = (_html, count = 100) => {
  const rawText = remove_tags(_html);
  const text = rawText?.replace(/"/g, "");
  return text.slice(0, count) + (text.length > count ? "..." : "");
};

//calculate reading time
export const readingTime = (text) => {
  let str = remove_tags(text);
  let wordCount = str.match(/(\w+)/g)?.length;
  if (wordCount <= 0) {
    wordCount = 100;
  }
  let count_time = Math.floor(wordCount / 200);

  return count_time;
};

//print html markup
export const createMarkup = (html) => {
  return {
    __html: DOMPurify.sanitize(html),
  };
};

//count word form string
export const wordCount = (str) => {
  return str.split(" ").length;
};

export const htmlParse = (str) => {
  if (!str || str == "") {
    return false;
  }
  return parse(sanitize(str, { FORCE_BODY: true }));
};

//set first string cpaatilize
export const capitalizeFirstLetter = (string, letter = 1) => {
  if (!string) return;
  return string[0].toUpperCase() + string.slice(letter);
};

export const ucfirst = (string, letter = 1) => {
  if (string) {
    return string[0].toUpperCase() + string.slice(letter);
  }
  return string;
};

//file size check
export const checkIfFilesAreTooBig = (files) => {
  let valid = true;
  if (files) {
    files.map((file) => {
      const size = file.size / 1024 / 1024;
      if (size > 10) {
        valid = false;
      }
    });
  }
  return valid;
};

//filte type chack
export const checkIfFilesAreCorrectType = (files) => {
  let valid = true;
  if (files) {
    files.map((file) => {
      if (!["application/pdf", "image/jpeg", "image/png"].includes(file.type)) {
        valid = false;
      }
    });
  }
  return valid;
};

// numFormatter
export const numFormatter = (num) => {
  if (num > 999 && num < 1000000) {
    return (num / 1000).toFixed(1) + "K"; // convert to K for number from > 1000 < 1 million
  } else if (num > 1000000) {
    return (num / 1000000).toFixed(1) + "M"; // convert to M for number from > 1 million
  } else if (num > 1000000000) {
    return (num / 1000000000).toFixed(1) + "B"; // convert to B for number from > 1 billion
  } else if (num < 900) {
    return num; // if value < 1000, nothing to do
  }
};

export function shortenLargeNumber(num, digits) {
  var units = ["K", "M", "G", "T", "P", "E", "Z", "Y"],
    decimal;

  for (var i = units.length - 1; i >= 0; i--) {
    decimal = Math.pow(1000, i + 1);

    if (num <= -decimal || num >= decimal) {
      return +(num / decimal).toFixed(digits) + units[i];
    }
  }

  return num;
}

//calculate duration
export const calcDuration = (duration) => {
  let courseDuration = duration / 12;
  let courseText = "";
  if (courseDuration === 0) {
    courseText = "0 Year";
  } else if (courseDuration === 0.5) {
    courseText = "6 Months";
  } else if (courseDuration === 1) {
    courseText = "1 Year";
  } else {
    courseText = `${courseDuration} Years`;
  }
  return courseText;
};

//react selecft style
export const reactSelectStyle = {
  control: (base, state) => ({
    ...base,
    height: "50px",
    boxShadow: " 0px 2px 5px rgba(38, 51, 77, 0.03)",
    borderColor: "none",
    "&:hover": { borderColor: "none" }, // border style on hover
    borderColor: state.isFocused ? "#e0e0e0" : "#e0e0e0",
  }),
  option: (provided, state) => ({
    ...provided,
    cursor: "pointer",
    color: state.isSelected ? "#F47F20" : "#B5B5C3",
    backgroundColor: state.isSelected
      ? "#F5F6FC"
      : state.isFocused
      ? "#fff"
      : provided.backgroundColor,
    "&:hover": {
      backgroundColor: "#F5F6FC",
      color: "#F47F20",
    },
  }),
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
};

//react select for Admin
export const reactSelectStyleAdmin = {
  control: (base, state) => ({
    ...base,
    // height: "30px",
    // minHeight: "30px",
    // fontSize: "11px",
    // borderColor: state.isFocused ? "#CFD7DF" : "#CFD7DF",
    // border: "1px solid #e5e5e5",
    height: "34px",
    minHeight: "34px",
    fontSize: "12px",
    boxShadow: "none",
    "&:hover": { borderColor: "none" }, // border style on hover
    borderColor: state.isFocused ? "#12344d" : "#e5e5e5",
    borderRadius: "2px",
  }),
  option: (provided, state) => ({
    ...provided,
    cursor: "pointer",
    // fontSize: "11px",
    fontSize: "12px",
    color: state.isSelected ? "#F47F20" : "#12344D",
    backgroundColor: state.isSelected
      ? "#F5F6FC"
      : state.isFocused
      ? "#fff"
      : provided.backgroundColor,
    "&:hover": {
      backgroundColor: "#F5F6FC",
      color: "#F47F20",
    },
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    padding: "5px 10px",
    position: "absolute",
  }),
  menu: (provided, state) => ({
    ...provided,
    padding: "4px 8px",
    width: "100%",
    marginTop: "5px",
    boxShadow: "0px 5px 8px #EBEFF3",
    borderRadius: "0px",
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    padding: "0",
    position: "absolute",
    right: "10px",
    top: "6px",
    // top: "4px",
    // fontSize: "1px",
    width: "10px",
    height: "10px",
  }),
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  placeholder: (provided) => ({
    ...provided,
    // color: "#b5b5c3",
    color: "#ccc",
    fontWeight: "300",
  }),
};

//create styles for react select
export const selectStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: "white",
    padding: "5px",
    border: "1px solid #ebe9f1 !important",
    boxShadow: "0 !important",
    "&:hover": {
      border: "1px solid #ebe9f1 !important",
    },
  }),
  option: (styles, { isDisabled, isFocused, isSelected }) => {
    const color = "#333";
    return {
      ...styles,
      color: isSelected ? "#F47F20" : "#B5B5C3",
      backgroundColor: isSelected
        ? "#F5F6FC"
        : isFocused
        ? "#F5F6FC"
        : styles.backgroundColor,
      "&:hover": {
        backgroundColor: "#F5F6FC",
        color: "#F47F20",
      },
      cursor: isDisabled ? "not-allowed" : "pointer",
    };
  },
  menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
  menu: (provided) => ({ ...provided, zIndex: 9999 }),
  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      color: "#c2c2c2",
    };
  },
};

//generate admin slug
export const redirectAdmin = (userInfo = undefined) => {
  if (!userInfo) return false;
  if (userInfo.userTypeSlug && userInfo.userTypeSlug == "student") {
    return "/app/student";
  } else if (userInfo.userTypeSlug && userInfo.userTypeSlug == "university") {
    return "/app/university";
  }
  return userInfo;
};

//check empty object
export const isEmpty = (obj) => {
  if (!obj) return true;
  return Object.keys(obj).length === 0;
};

//Objet to Query String
export const objectToQueryString = (obj) => {
  var str = [];
  for (var p in obj)
    if (obj.hasOwnProperty(p)) {
      let qeryValue = obj[p] ? obj[p].toString() : "";
      if (qeryValue != "") {
        str.push(
          encodeURIComponent(p) + "=" + encodeURIComponent(qeryValue.toString())
        );
      }
    }
  return str.join("&");
};

//serialize qeury
export const serialize = function(obj) {
  var str = [];
  for (var p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  return str.join("&");
};

//generate Options
export const generateOptions = (Items, level, value, childKey) => {
  if (Items && Items.length > 0) {
    return Items.map((item) => {
      if (item[childKey] && item[childKey].length > 0) {
        return {
          label: item[level],
          value: item[value],
          children:
            item.children &&
            item.children.length > 0 &&
            item.children.map((childItem) => {
              return {
                label: childItem[level],
                value: item[value] + "_" + childItem.slug,
                children:
                  childItem.children &&
                  childItem.children.length > 0 &&
                  childItem.children.map((childItem2) => {
                    return {
                      label: childItem2[level],
                      value: childItem[value] + "_" + childItem2.slug,
                    };
                  }),
              };
            }),
        };
      } else {
        return {
          label: item[level],
          value: item[value],
        };
      }
    });
  } else {
    return [];
  }
};

//bgenerate Options
export const geneateSelectedOptions = (
  Items,
  selectedArray,
  value,
  childKey
) => {
  //check if null
  if (!selectedArray) return { selectedOptions: [], expandOptions: [] };

  const selectedOptions = [];
  const expandOptions = [];

  if (Items && Items.length > 0) {
    Items.map((item) => {
      if (item[childKey] && item[childKey].length > 0) {
        item.children &&
          item.children.length > 0 &&
          item.children.map((childItem) => {
            if (childItem[childKey] && childItem[childKey].length > 0) {
              childItem.children &&
                childItem.children.length > 0 &&
                childItem.children.map((childItem2) => {
                  //set selected
                  if (
                    selectedArray.includes(item[value]) &&
                    selectedArray.includes(childItem.slug) &&
                    selectedArray.includes(childItem2.slug)
                  ) {
                    selectedOptions.push(
                      childItem[value] + "_" + childItem2.slug
                    );
                    expandOptions.push(childItem[value]);
                  }
                });
            } else {
              //return selected
              if (selectedArray.includes(childItem.slug)) {
                selectedOptions.push(item[value] + "_" + childItem.slug);
                expandOptions.push(item[value]);
              }
            }
          });
      } else {
        if (selectedArray.includes(item[value])) {
          selectedOptions.push(item[value]);
          expandOptions.push(item[value]);
        }
      }
    });
    return {
      selectedOptions: Array.from(new Set(selectedOptions)),
      expandOptions: Array.from(new Set(expandOptions)),
    };
  } else {
    return { selectedOptions: [], expandOptions: [] };
  }
};

//shuffelArray
export const arrayShuffler = (array) => {
  return array.sort((a, b) => 0.5 - Math.random());
};

//get assets by location
export const getAssets = (path) => {
  const url = process.env.NEXT_PUBLIC_URL ?? "https://myunisearch.com";
  return `${url}/${path}`;
};

//Set Cookies
export const setCookie = (name, value, days) => {
  var expires = "";
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
};

//Get Cookies
export const getCookie = (name) => {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};

//Remove Cookies
export const eraseCookie = (name) => {
  document.cookie = name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
};

//Downlaod Button
export const DownloadFile = (src, fileName) => {
  //Create XMLHTTP Request.
  var req = new XMLHttpRequest();
  req.open("GET", src, true);
  req.responseType = "blob";
  req.onload = function() {
    var blob = new Blob([req.response], { type: "application/octetstream" });

    //Check the Browser type and download the File.
    var isIE = false || !!document.documentMode;
    if (isIE) {
      window.navigator.msSaveBlob(blob, fileName);
    } else {
      var url = window.URL || window.webkitURL;
      link = url.createObjectURL(blob);
      var a = document.createElement("a");
      a.setAttribute("download", fileName);
      a.setAttribute("href", link);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };
  req.send();
};

export const gender = [
  {
    label: "Male",
    value: "male",
  },
  {
    label: "Female",
    value: "female",
  },
  {
    label: "Other",
    value: "other",
  },
];
export const maritalStatus = [
  {
    label: "Unmarried",
    value: "unmarried",
  },
  {
    label: "Married",
    value: "married",
  },
];

//handle University Favorite
export const isUniFavorite = (profileInfo, id) => {
  if (
    profileInfo &&
    profileInfo.favoriteUniversityList &&
    profileInfo.favoriteUniversityList.map(Number).includes(parseInt(id))
  ) {
    return true;
  } else {
    return false;
  }
};

//handle Course Favorite
export const isCourseFavorite = (profileInfo, id) => {
  if (
    profileInfo &&
    profileInfo.favoriteCourseList &&
    profileInfo.favoriteCourseList.includes(parseInt(id))
  ) {
    return true;
  } else {
    return false;
  }
};

//handle University Compare
export const isReadingListed = (profileInfo, reference, referenceId) => {
  if (
    profileInfo &&
    profileInfo?.readingList &&
    profileInfo?.readingList[reference] &&
    profileInfo?.readingList[reference].includes(parseInt(referenceId))
  ) {
    return true;
  } else {
    return false;
  }
};

//handle University Compare
export const isUniCompare = (profileInfo, id) => {
  if (
    profileInfo &&
    profileInfo.compareUniversityList &&
    profileInfo.compareUniversityList.includes(parseInt(id))
  ) {
    return true;
  } else {
    return false;
  }
};

//handle Course Compare
export const isCourseCompare = (profileInfo, id) => {
  if (
    profileInfo &&
    profileInfo.compareCourseList &&
    profileInfo.compareCourseList.includes(parseInt(id))
  ) {
    return true;
  } else {
    return false;
  }
};

//convert string to slugify
export const generateSlugify = (string) => {
  return string
    .toString()
    .toLowerCase()
    .replace(/ /g, "-")
    .replace(/[^\w-]+/g, "");
};

//slugify to String
export const slugToString = (slug) => {
  let slugstring = slug.toString();
  slugstring = slugstring.replace(/-/g, " ");

  return slugstring.replace(/\w\S*/g, function(txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

//slugify to String
export const slugToString2 = (slug) => {
  const slugstr = slug.toString();
  const slugstring = slugstr.replace(/-/g, " ");
  const slugstring2 = slugstring.replace(/,/g, ", ");

  return slugstring2.replace(/\w\S*/g, function(txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

//export timezone
export const getTimeZone = (ofsetStatus = false) => {
  const momentZone = require("moment-timezone");
  var timeZones = momentZone.tz.names();
  var offsetTmz = [];

  for (var i in timeZones) {
    if (ofsetStatus) {
      let tzone = {
        label: `(GMT ${momentZone.tz(timeZones[i]).format("Z")} ) ${
          timeZones[i]
        }`,
        value: momentZone.tz(timeZones[i]).utcOffset(),
      };
      offsetTmz.push(tzone);
    } else {
      let tzone = {
        label: `(GMT ${momentZone.tz(timeZones[i]).format("Z")} ) ${
          timeZones[i]
        }`,
        value: timeZones[i],
      };
      offsetTmz.push(tzone);
    }
  }
  return offsetTmz;
};

//filter File Types
export const filterFileType = (str) => {
  if (!str) return false;

  if (str == "image/jpg" || str == "image/jpeg" || str == "image/png") {
    return "image";
  } else if (str == "application/pdf") {
    return "pdf";
  } else if (
    str == "application/xls" ||
    str == "application/xlsx" ||
    str == "application/vnd.ms-excel"
  ) {
    return "excel";
  } else if (
    str ==
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
    str == "application/docx"
  ) {
    return "doc";
  }
};

//time counter function
export const whatTimeAgo = (date) => {
  if (!date) return false;
  const moment = require("moment");
  const d = new Date(date);

  return moment(d).fromNow();
  // return moment(d).format("D MMMM, YYYY");
};

//new line formater

export const newLineText = (text) => {
  if (!text) return "";
  const newText = text
    .split("\n")
    .map((str, i) => (
      <p key={`${i}_rand_${(Math.random() + 1).toString(36).substring(7)}`}>
        {str}
      </p>
    ));
  return newText;
};

export const toAbsoluteUrl = (pathname) =>
  process.env.NEXT_PUBLIC_URL + pathname;

//Date Formater
export const dateFormater = (dateString, formate = "MMM Do YYYY") => {
  if (!dateString) return "";

  const moment = require("moment");
  const d = new Date(`${dateString}`);
  return moment(d).format(formate);
};

//set time period
export const timePeriods = () => {
  const moment = require("moment");

  let today = moment().format("MMM D");

  let yesterday = moment()
    .add(-1, "days")
    .format("MMM D");

  let sevenDays = moment()
    .add(-7, "days")
    .format("MMM D");

  let thirtyDays = moment()
    .add(-30, "days")
    .format("MMM D");

  let lastsevendays = `${sevenDays} - ${today}`;

  let lastthirtydays = `${thirtyDays} - ${today}`;

  const timeData = [
    {
      id: 1,
      title: "All time periods",
      value: "all",
      time: "",
    },
    {
      id: 2,
      title: "Today",
      value: "today",
      time: today,
    },
    {
      id: 3,
      title: "Yesterday",
      value: "yesterday",
      time: yesterday,
    },
    {
      id: 4,
      title: "Last 7 days",
      value: "lastsevendays",
      time: lastsevendays,
    },
    {
      id: 5,
      title: "Last 30 days",
      value: "lastthirtydays",
      time: lastthirtydays,
    },
    {
      id: 6,
      title: "Custom Period",
      value: "custom",
      time: "",
    },
  ];

  return timeData;
};

//get student activities
export const getActivities = () => {
  return [
    {
      label: "Conversation",
      value: "conversation",
    },
    {
      label: "Chat",
      value: "chat",
    },
    {
      label: "Review",
      value: "review",
    },
    // {
    //   label: "Shortlist Courses",
    //   value: "shortlist_courses",
    // },
    // {
    //   label: "Shortlist University",
    //   value: "shortlist_university",
    // },
    {
      label: "Favorite University",
      value: "favorite_university",
    },
    {
      label: "Favorite Course",
      value: "favorite_course",
    },
    {
      label: "Compare University",
      value: "compare_university",
    },
    {
      label: "Compare Courses",
      value: "compare_courses",
    },
    {
      label: "Article Bookmark",
      value: "article_bookmark",
    },
    {
      label: "Prospectus Download",
      value: "prospectus",
    },
    {
      label: "Course Guide Download",
      value: "course_guide",
    },
  ];
};

export const getActivityIcons = (type = false) => {
  if (!type) return "";

  const types = {
    message: "assets/icons/admin/mail_outline.svg",
    conversation: "assets/icons/admin/mail2.svg",
    chat: "assets/icons/admin/chat.svg",
    review: "assets/icons/admin/star_outline.svg",
    shortlist_courses: "assets/icons/admin/bookmark_outline1.svg",
    shortlist_university: "assets/icons/admin/bookmark_outline1.svg",
    favorite_university: "assets/icons/admin/heart.svg",
    favorite_course: "assets/icons/admin/heart.svg",
    compare_university: "assets/icons/admin/bookmark_outline1.svg",
    compare_courses: "assets/icons/admin/bookmark_outline1.svg",
    article_bookmark: "assets/icons/admin/bookmark.svg",
    brochure: "assets/icons/admin/documents_outline.svg",
    prospectus: "assets/icons/admin/download.svg",
    course_guide: "assets/icons/admin/documents_outline.svg",
    profile_evolution_edit: "assets/icons/admin/evaluation.svg",
  };

  return <ReactSVG src={getAssets(types[type.trim()])} />;
};

//generate Years
export const getYears = (limit = 5) => {
  const currentYear = new Date().getFullYear();

  const returnDate = [
    {
      label: "Select Year",
      value: "",
    },
  ];
  for (let i = currentYear; i >= currentYear - limit; i--) {
    returnDate.push({
      label: i,
      value: i,
    });
  }
  return returnDate;
};

//Nav Title Set
export const generateNavTitle = (key) => {
  if (!key) return false;

  let titleArray = {
    overview: "Overview",
    "admission-faq": "Admission FAQ",
    ranking: "Rankings",
    courses: "Courses",
    blog: "Blog",
    reviews: "Reviews",
    contact: "Contact",
    gallery: "Gallery",
  };

  if (titleArray[key] && titleArray[key] != "") {
    return titleArray[key];
  } else {
    return key;
  }
};

// get file name extension
export const getFileExtension = (filename) => {
  if (!filename) return;
  const extension = filename.substring(
    filename.lastIndexOf(".") + 1,
    filename.length
  );
  return extension;
};

// HEX to RGB conversion
export const hexToRGB = (h) => {
  if (!h) return;
  let r = 0,
    g = 0,
    b = 0;
  // 3 digits
  if (h.length == 4) {
    r = "0x" + h[1] + h[1];
    g = "0x" + h[2] + h[2];
    b = "0x" + h[3] + h[3];
    // 6 digits
  } else if (h.length == 7) {
    r = "0x" + h[1] + h[2];
    g = "0x" + h[3] + h[4];
    b = "0x" + h[5] + h[6];
  }
  return +r + "," + +g + "," + +b;
};

export function addGoogleAdScript(paragraphs, adScript, adPosition = 50) {
  if (!paragraphs) return;
  // Define the position where you want to insert the ad (e.g., after the second paragraph)

  // Split the content into an array of paragraphs
  const paragraphsArray = paragraphs.split("</p>");

  // Insert the ad script after the specified position
  if (adPosition <= paragraphsArray.length) {
    const adDiv = `<div class="flex justify-center mb-6">${adScript}</div>`;
    paragraphsArray.splice(adPosition, 0, adDiv);
  }

  // Join the paragraphs back together with the ad script included
  const modifiedContent = paragraphsArray.join("</p>");

  return htmlParse(modifiedContent);
}
