import { BehaviorSubject } from "rxjs";
import Router from "next/router";
import API from "@unisearch/helpers/api";
import { EncryptionStorage } from "@unisearch/helpers";

const authSubject = new BehaviorSubject(
  process.browser && JSON.parse(EncryptionStorage.getItem("AUTH"))
);

//login
const login = async (payload) => {
  return new Promise(async (resolve, reject) => {
    await API.post(`/auth/login`, payload).then((rsp) => {
      if (!rsp.error && rsp.statusCode === 201) {
        //auth info
        const authInfo = rsp.data;

        if (authInfo.access_token != "" && authInfo.userTypeId) {
          //set state
          authSubject.next(authInfo);

          //set localstorage
          EncryptionStorage.setItem("AUTH", authInfo);

          //return resolved
          resolve(authInfo);
        } else {
          reject("Something wrong!");
        }
      } else {
        reject(rsp.message);
      }
    });
  }).catch((err) => {
    return err;
  });
};

//login
const register = async (payload) => {
  return new Promise(async (resolve, reject) => {
    await API.post(`/user/registration`, payload).then((rsp) => {
      if (!rsp.error && rsp.statusCode === 201) {
        resolve(rsp.data);
      }

      reject(rsp.message);
    });
  });
};

//login
const verifyOtp = async (payload) => {
  return new Promise(async (resolve, reject) => {
    await API.post(`/auth/verify-otp`, payload).then((rsp) => {
      if (rsp.error) {
        reject(rsp.message);
      }
      resolve(rsp.data);
    });
  });
};

//Resend OTP
const resentOtp = async (payload) => {
  return new Promise(async (resolve, reject) => {
    await API.post(`/auth/resend-otp`, payload).then((rsp) => {
      if (rsp.error) {
        reject(rsp.message);
      }
      resolve(rsp.data);
    });
  });
};

//check exist
const checkExist = (payload) => {
  return new Promise(async (resolve, reject) => {
    await API.post(`/check-email`, payload).then((rsp) => {
      if (rsp.error) {
        reject(rsp.message);
      }
      resolve(rsp.data);
    });
  });
};

//set logout
const logout = (returnUrl = undefined) => {
  const loggedUser = { ...getProfileData() };

  EncryptionStorage.removeItem("AUTH");
  EncryptionStorage.removeItem("PROFILE");
  authSubject.next(null);

  if (returnUrl) {
    if (typeof window !== "undefined") {
      // Router.push(returnUrl);
      window.location.replace(`${process.env.NEXT_PUBLIC_URL}/${returnUrl}`);
      return true;
    }
  } else if (
    loggedUser.userTypeSlug != "student" ||
    loggedUser.userTypeSlug != "mentor"
  ) {
    if (typeof window !== "undefined") {
      // Router.push("/signin");
      window.location.replace(`${process.env.NEXT_PUBLIC_URL}/signin`);
      return true;
    }
  } else if (
    loggedUser.userTypeSlug != "university" ||
    loggedUser.userTypeSlug != "counsellor" ||
    loggedUser.userTypeSlug != "agent"
  ) {
    if (typeof window !== "undefined") {
      // Router.push("/partner-login");
      window.location.replace(`${process.env.NEXT_PUBLIC_URL}/partner-login`);
      return true;
    }
  } else {
    if (typeof window !== "undefined") {
      // Router.push("/signin");
      window.location.replace(`${process.env.NEXT_PUBLIC_URL}/partner-login`);
      return true;
    }
  }
  return true;
};

//get user profile
const getProfile = async () => {
  return new Promise(async (resolve, reject) => {
    await API.get(`/auth/profile`).then((rsp) => {
      if (rsp.error) {
        //logout();
        reject(rsp.message);
      } else {
        //auth info
        const ProfileInfo = rsp.data;

        if (ProfileInfo) {
          //set localstorage
          EncryptionStorage.setItem("PROFILE", rsp.data);
          return resolve(ProfileInfo);
        } else {
          //logout();
          reject("Something wrong!");
        }
      }
    });
  }).catch((err) => {
    return { error: true, message: err };
  });
};

//Get User Profile Data
const getProfileData = () => {
  if (!authSubject.value) {
    return false;
  }

  let ProfileInfo = JSON.parse(EncryptionStorage.getItem("PROFILE"));
  if (ProfileInfo && ProfileInfo.userTypeSlug) {
    return ProfileInfo;
  }
  return false;
};

export const authService = {
  loggedUser: authSubject.asObservable(),
  get loggedUserValue() {
    return authSubject.value;
  },
  login,
  logout,
  getProfile,
  register,
  verifyOtp,
  resentOtp,
  checkExist,
  getProfileData,
};
