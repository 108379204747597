const { useState, useEffect } = require("react");

export const useNetwork = () => {
  let [networkStatus, setNetworkStatus] = useState({
    online: true,
    swap: false,
  });

  const setOnline = () => {
    setNetworkStatus((prev) => ({ ...prev, online: true }));
  };
  const setOffline = () => {
    setNetworkStatus((prev) => ({ ...prev, online: false }));
  };
  const setOnChange = () => {
    setNetworkStatus((prev) => ({ ...prev, swap: true, online: true }));
  };

  // Register the event listeners
  useEffect(() => {
    window.addEventListener("offline", setOffline);
    window.addEventListener("online", setOnline);
    const connection =
      window.navigator.connection ||
      window.navigator.mozConnection ||
      window.navigator.webkitConnection ||
      null;

    connection?.addEventListener("change", setOnChange);
    // cleanup if we unmount
    return () => {
      window.removeEventListener("offline", setOffline);
      window.removeEventListener("online", setOnline);
      connection?.removeEventListener("change", setOnChange);
    };
  }, []);

  return networkStatus;
};
