import { atom } from "recoil";

const month = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
const d = new Date();
let monthName = month[d.getMonth()];

const weatherAtom = atom({
  key: "weather",
  default: {
    countryName: "",
    currentMonth: monthName,
    weatherData: [],
  },
});

export { weatherAtom };
