import { systemAtom } from "@_recoil/_state";
import { useSetRecoilState } from "recoil";
import { EncryptionStorage } from "@unisearch/helpers";

export { useSystemActions };

function useSystemActions() {
  //set actions
  const setSystem = useSetRecoilState(systemAtom);

  //set return ulr
  const setReturnUrl = (url) => {
    setSystem((prev) => ({
      ...prev,
      returnUrl: url,
    }));

    if (typeof window !== "undefined") {
      EncryptionStorage.setItem("returnUrl", url);
    }
    return true;
  };

  //get return url
  const removeReturnUrl = () => {
    setSystem((prev) => ({
      ...prev,
      returnUrl: false,
    }));
    if (typeof window !== "undefined") {
      localStorage.removeItem("@UNI_returnUrl");
    }
    return true;
  };

  //set return ulr
  const setSystemCurrency = (value) => {
    setSystem((prev) => ({
      ...prev,
      currency: value,
    }));

    if (typeof window !== "undefined") {
      EncryptionStorage.setItem("syscur", value);
    }
    return true;
  };

  //set return ulr
  const setLoading = (value) => {
    setSystem((prev) => ({
      ...prev,
      loading: value,
    }));
    return true;
  };

  //set return ulr
  const setVisitorCountry = (value) => {
    setSystem((prev) => ({
      ...prev,
      visitor_country: value,
    }));
    return true;
  };

  return {
    setReturnUrl,
    removeReturnUrl,
    setSystemCurrency,
    setLoading,
    setVisitorCountry,
  };
}
