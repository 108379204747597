import { getAssets } from "@unisearch/helpers";
import { useState } from "react";

import { authService } from "@unisearch/auth";
import { swalConfirm, swalError, swalSuccess } from "@unisearch/helpers";

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as Yup from "yup";

import { useRouter } from "next/router";

import { CircularProgress } from "@mui/material";
import styles from "./email_verification.module.scss";
import Image from "next/legacy/image";

export const EmailVerification = () => {
  const router = useRouter();
  const { email, type } = router.query;
  const [loading, setLoading] = useState(false);
  const [verifyLoading, setVerifyLoading] = useState(false);

  const validationSchema = Yup.object().shape({
    otpCode: Yup.string().required("OTP Code is Required"),
  });

  const formOptions = {
    resolver: yupResolver(validationSchema),
  };

  // get functions to build form with useForm() hook
  const { register, handleSubmit, setError, formState, setValue } = useForm(
    formOptions
  );

  //set form error
  const { errors } = formState;

  //when submit form
  function onSubmit({ otpCode }) {
    swalConfirm("", "Confirm OTP?", "Yes Confirm").then(async (data) => {
      if (data.isConfirmed) {
        setVerifyLoading(true);
        //payload
        const payload = {
          userTypeSlug: type,
          otpCode,
        };
        return authService
          .verifyOtp(payload)
          .then((rsp) => {
            if (!rsp.error) {
              setVerifyLoading(false);
              swalSuccess(rsp.message, "Success!").then(
                setTimeout(() => {
                  const returnUrl = `/signin`;
                  router.push(returnUrl);
                }, 3000)
              );
            }
            if (rsp.error) {
              swalError(rsp.message);
            }
          })
          .catch((errors) => {
            setVerifyLoading(false);
            swalError(errors, "Sorry!");
          });
      }
    });
  }

  const resendCode = async () => {
    swalConfirm("", "Resend OTP?", "Yes, Please Resend").then(async (data) => {
      if (data.isConfirmed) {
        setLoading(true);
        //resent payload
        const payload = {
          email,
          userTypeSlug: type,
        };

        await authService
          .resentOtp(payload)
          .then((rsp) => {
            //success
            if (!rsp.error) {
              swalSuccess(rsp, "Success!");
            }
            //error resonse
            if (rsp.error) {
              swalError(rsp.message);
            }
          })
          .catch((errors) => {
            swalError(
              `Please try again with a valid email address`,
              `Oops! ${errors}`
            );
          });

        setLoading(false);
      }
    });
  };

  return (
    <section className={styles.section_auth}>
      <div className={styles.side_bg}></div>
      <div className="container">
        <div className={styles.wrapper}>
          <div className={styles.left_wrapper}>
            <div className={styles.logo_and_title_wrapper}>
              <Image
                src={getAssets("assets/logo-beta.webp")}
                alt="UniSearch Logo"
                blurDataURL={getAssets("assets/logo-beta.webp")}
                placeholder="blur"
                width={186}
                height={30}
              />
              <div className={styles.title}>
                Discover study abroad opportunities around the world with
                UniSearch
              </div>
            </div>
            <div className={styles.image_wrapper}>
              <img
                src={getAssets("assets/images/illustration_singin.png")}
                alt="singin"
              />
            </div>
          </div>
          <div className={styles.right_wrapper}>
            <div className={styles.form_title_wrapper}>
              <div className={styles.form_title}>Verify Your Email</div>
              <div className={styles.form_sub_title}>
                We have sent a verification code to <span>{email}</span> <br />
                Please enter the code below to access your UniSearch profile!
              </div>
            </div>

            <div className={styles.form_wrapper}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className={styles.inputs_wrapper}>
                  <div className={styles.form_group}>
                    <input
                      type="text"
                      className={`${errors.otpCode ? styles.error : ""} ${
                        styles.otpInput
                      }`}
                      name={`otpCode`}
                      {...register("otpCode")}
                    />
                    <div className={styles.error_msg}>
                      {errors.otpCode?.message}
                    </div>
                  </div>
                </div>

                <div className={styles.btn_group_wrapper}>
                  <button
                    type="submit"
                    className="btn btn-bg btn-secondary"
                    disabled={verifyLoading}
                  >
                    {verifyLoading && (
                      <CircularProgress size={15} style={{ color: "#fff" }} />
                    )}{" "}
                    Verify Email
                  </button>
                </div>
              </form>
              <div className={styles.resend_text}>
                Didn’t receive the code?{" "}
                <button
                  onClick={() => {
                    resendCode();
                  }}
                  disabled={loading}
                >
                  {loading && <CircularProgress size={10} />} Resend
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default EmailVerification;
