import API from "@unisearch/helpers/api";
import { evaluationAtom } from "@_recoil/_state";
import { useRouter } from "next/router";
import { useSetRecoilState } from "recoil";

export { useEvaluationActions };

function useEvaluationActions() {
  //set router
  const router = useRouter();

  //set actions
  const setEvaluation = useSetRecoilState(evaluationAtom);

  //get countries
  const getCountries = async () => {
    //set loading
    setEvaluation((curVal) => ({ ...curVal, is_loading: true }));

    //fetch country
    await API.get(`/public/country/workable`).then((resp) => {
      if (resp.data) {
        setEvaluation((curVal) => ({
          ...curVal,
          countrylist: resp.data,
          is_loading: false,
        }));
      }
    });
  };

  //get Course Levels
  const getCourseLevels = async () => {
    //set loading
    setEvaluation((curVal) => ({ ...curVal, is_loading: true }));

    //fetch  Course Level
    await API.get(`/public/course-level`).then((resp) => {
      if (resp.data) {
        setEvaluation((pVal) => ({
          ...pVal,
          levels: resp.data,
          is_loading: false,
        }));
      }
    });
  };

  //get Height Education Levels
  const getheightEduLevels = async () => {
    //set loading
    setEvaluation((prev) => ({ ...prev, is_loading: true }));

    //fetch  Course Level
    await API.get(`/public/study/highest-level-education/dropdown`).then(
      (resp) => {
        if (!resp.error && resp.data) {
          setEvaluation((pVal) => ({
            ...pVal,
            heightEducationLevel: resp.data,
            isLoading: false,
          }));
        }
      }
    );
  };

  //get Specializations
  const getSpecializations = async () => {
    //set loading
    setEvaluation((prev) => ({ ...prev, is_loading: true }));

    //fetch specializtion
    await API.get(`/public/specialization`).then((resp) => {
      if (resp.data) {
        setEvaluation((prev) => ({
          ...prev,
          specializations: resp.data,
          is_loading: false,
        }));
      }
    });
  };

  //get Sub Specializations
  const getSubSpecializations = async (id) => {
    //set loading
    setEvaluation((prev) => ({ ...prev, is_loading: true }));

    return new Promise(async (resolve, reject) => {
      //get sub specializations
      await API.get(`/public/department/${id}`).then((rsp) => {
        setEvaluation((prev) => ({ ...prev, is_loading: false }));

        if (rsp.error) {
          reject(rsp.message);
        }

        if (rsp.data) {
          setEvaluation((prev) => ({
            ...prev,
            departments: rsp.data,
          }));

          resolve(rsp.data);
        } else {
          reject(rsp.message);
        }
      });
    });
  };

  //get all exams
  const getExams = async (id) => {
    //set loading
    setEvaluation((prev) => ({ ...prev, is_loading: true }));

    await API.get(`/public/exam`).then((resp) => {
      if (resp.data) {
        setEvaluation((prev) => ({
          ...prev,
          exams: resp.data,
          is_loading: false,
        }));
      }
    });
  };

  //get all Objective
  const getObjective = async () => {
    //set loading
    setEvaluation((prev) => ({ ...prev, is_loading: true }));

    await API.get(`/public/evaluation-objective`).then((resp) => {
      if (resp.data) {
        setEvaluation((prev) => ({
          ...prev,
          objectives: resp.data,
          is_loading: false,
        }));
      }
    });
  };

  //get Last Educations Level
  // const getLastEducations = async (id) => {
  //   //set loading
  //   setEvaluation((prev) => ({ ...prev, is_loading: true }));

  //   await API.get(`/public/education/${id}`).then((resp) => {
  //     if (resp.data) {
  //       setEvaluation((prev) => ({
  //         ...prev,
  //         educationLevels: resp.data,
  //         is_loading: false,
  //       }));
  //     }
  //   });
  // };

  return {
    getCountries,
    getSpecializations,
    getSubSpecializations,
    getCourseLevels,
    getheightEduLevels,
    getExams,
    getObjective,
    // getLastEducations,
  };
}
