const { createSlice } = require("@reduxjs/toolkit");

const homePopupSlice = createSlice({
  name: "homePopup",
  initialState: {
    isOpen: false,
  },

  reducers: {
    homeHandleIsOpen: (state, action) => {
      state.isOpen = action.payload.isOpen;
    },
  },
});

export const { homeHandleIsOpen } = homePopupSlice.actions;
export default homePopupSlice.reducer;
