/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import axios from "axios";
import { useState, useEffect } from "react";
import { useRouter } from "next/router";
import { RecoilRoot } from "recoil";
import { authService } from "@unisearch/auth";
import { useNetwork } from "_hooks/useNetwork";
import { Provider } from "react-redux";
import { store } from "@redux/store";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import "react-modal-video/scss/modal-video.scss";
import "react-toastify/dist/ReactToastify.css";
import "../styles/App.scss";
import Cookies from "js-cookie";

const theme = createTheme({
  palette: {
    primary: {
      main: "#f47f20", // red color
    },
  },
  typography: {
    fontFamily: "Poppins, sans-serif",
  },
});

const App = ({ Component, pageProps }) => {
  const networkStatus = useNetwork();
  const getIpInfo = async () => {
    try {
      const res = await axios.get("https://geolocation-db.com/json/");
      if (
        res.data &&
        res.data?.country_code !== "" &&
        res.status !== 502 &&
        res.status !== 404
      ) {
        Cookies.set("default_country", res.data.country_code.toLowerCase(), {
          expires: 1 / 24,
        });
      } else {
        Cookies.set("default_country", "in");
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (networkStatus.online) {
      let locationData = Cookies.get("default_country");
      if (networkStatus.online && !locationData) {
        setTimeout(() => {
          getIpInfo();
        }, 1000);
        2;
      }
    } else {
      Cookies.remove("default_country");
    }

    if (networkStatus.swap && networkStatus.online) {
      setTimeout(() => {
        getIpInfo();
      }, 3000);
    }
  }, [networkStatus]);

  const router = useRouter();
  const [authorized, setAuthorized] = useState(true);

  function authCheck(url) {
    const protectedPaths = ["app"];
    const fullPath = url.split("?")[0];
    const path = fullPath.split("/").filter(Boolean);

    if (protectedPaths.includes(path[0])) {
      if (authService.loggedUserValue) {
        setAuthorized(true);
      } else {
        setAuthorized(false);
        router.push({
          pathname: "/signin",
          query: { returnUrl: router.asPath },
        });
      }
    } else {
      setAuthorized(true);
    }
  }

  useEffect(() => {
    // run auth check on initial load
    authCheck(router.asPath);

    // set authorized to false to hide page content while changing routes
    // const hideContent = () => setAuthorized(false);
    // router.events.on("routeChangeStart", hideContent);

    // run auth check on route change
    // router.events.on("routeChangeComplete", authCheck);

    // unsubscribe from events in useEffect return function
    return () => {
      // router.events.off("routeChangeStart", hideContent);
      // router.events.off("routeChangeComplete", authCheck);
    };
  }, []);

  // GOOGLE PAGE TRACKER
  const handleRouteChange = (url) => {
    if (window.gtag) {
      window.gtag("config", "G-CBM37S40N3", {
        page_path: url,
      });
    }
  };

  useEffect(() => {
    router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  /**
   * remove all console logs for productino
   */
  if (process.env.APP_STAGE === "prod") {
    //console.log(process.env.APP_STAGE)
    // console.log = () => {};
  }

  // return <RecoilRoot>{authorized && <Component {...pageProps} />}</RecoilRoot>;
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <RecoilRoot>
          <Component {...pageProps} />
        </RecoilRoot>
      </ThemeProvider>
    </Provider>
  );
};

export default App;
