import { useRouter } from "next/router";
import { authService } from "@unisearch/auth";
import { useSetRecoilState } from "recoil";
import { authAtom } from "@_recoil/_state";
import { swalError } from "@unisearch/helpers";

export { useAuthActions };

function useAuthActions() {
  //set router
  const router = useRouter();

  const setAuth = useSetRecoilState(authAtom);

  const login = async (username, password, loginType) => {
    //login action
    await authService.login(username, password, loginType).then((resp) => {
      setAuth({ user: "hello" });
      router.push({
        pathname: "/login",
        query: { returnUrl: router.asPath },
      });
    });
  };

  //Logout
  const logout = (returnPath = undefined) => {
    if (returnPath) {
      authService.logout(returnPath);
      setAuth(null);
      if (typeof window !== "undefined") {
        // router.push(returnPath);
        window.location.replace(`${process.env.NEXT_PUBLIC_URL}/${returnPath}`);
      }
    } else {
      authService.logout();
      setAuth(null);
      if (typeof window !== "undefined") {
        // router.push("/signin");
        window.location.replace(`${process.env.NEXT_PUBLIC_URL}/signin`);
      }
    }
  };

  //get user profile
  const getProfile = async () => {
    await authService.getProfile().then((resp) => {
      if (resp.error) {
        swalError(resp.message);
        logout();
      } else {
        setAuth(resp);
      }
    });
  };

  //Set Profile data
  const setProfile = (data) => {
    setAuth(data);
  };

  return {
    login,
    logout,
    getProfile,
    setProfile,
  };
}
