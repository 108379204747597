export const preferredLevel = [
  { value: "", label: "Select Session" },
  { value: "Postgradute", label: "Postgradute" },
  { value: "Undergradute", label: "Undergradute" },
  { value: "Others", label: "Others" },
];

export const preferredMonth = () => {
  let months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  let currentYear = new Date().getFullYear();
  let currMonth_id = new Date().getMonth();

  let next = [];
  let prev = [];

  for (let i = currMonth_id + 1; i < 12; i++) {
    let nextMonth_id = (currMonth_id + i) % 12;
    next.push({
      month: months[i],
      id: nextMonth_id + 1,
      year: currentYear,
    });
  }

  for (let j = currMonth_id; j >= 0; j--) {
    let prevMonth_id = (currMonth_id + j) % 12;
    prev.push({
      month: months[j],
      id: prevMonth_id,
      year: currentYear + 1,
    });
  }

  const timeSlot = next.concat(prev.reverse());

  return timeSlot.map((item) => {
    return {
      label: `${item?.month} - ${item?.year}`,
      value: `${item?.month}-${item?.year}`,
    };
  });
};
