// const secretKey = `${process.env.REACT_APP_SECRET_KEY}`;
// const prefix = `${process.env.APP_SECRET_KEY}`;
//https://stackoverflow.com/questions/39311514/how-to-decrypt-aes-with-cryptojs

// var CryptoJS = require("crypto-js");

// var key = CryptoJS.enc.Utf8.parse('b75524255a7f54d2726a951bb39204df');
// var iv  = CryptoJS.enc.Utf8.parse('1583288699248111');
// var text = "My Name Is Nghĩa";

// var encryptedCP = CryptoJS.AES.encrypt(text, key, { iv: iv });
// var decryptedWA = CryptoJS.AES.decrypt(encryptedCP, key, { iv: iv});
// var cryptText = encryptedCP.toString();
// console.log(cryptText);
// console.log(decryptedWA.toString(CryptoJS.enc.Utf8));

// //Decode from text
// var cipherParams = CryptoJS.lib.CipherParams.create({
//      ciphertext: CryptoJS.enc.Base64.parse(cryptText )
// });
// var decryptedFromText = CryptoJS.AES.decrypt(cipherParams, key, { iv: iv});
// console.log(decryptedFromText.toString(CryptoJS.enc.Utf8));

/*
 * load packages
 ***********************************************/
const CryptoJS = require("crypto-js");
const secretKey = `9z$C&F)H@McQfTjWnZr4u7x!A%D*G-KaNdRgUkXp2s5v8y/B?E(H+MbQeShVmYq3`;
const prefix = `@UNI`; //`${process.env.APP_KEY_PREFIX}`;

/*
 * setItem
 ***********************************************/
const setItem = (key, payload) => {
  let encryptedData = CryptoJS.AES.encrypt(
    JSON.stringify(payload),
    secretKey
  ).toString();

  if (typeof window !== "undefined") {
    //set encryption data in localStorage
    localStorage.setItem(`${prefix}_${key}`, encryptedData);

    //return enc data
    return encryptedData;
  }

  return false;
};

/*
 * setItem
 ***********************************************/
const getItem = (key) => {
  if (typeof window !== "undefined") {
    let encData = localStorage.getItem(`${prefix}_${key}`);
    if (encData) {
      var decryptedFromText = CryptoJS.AES.decrypt(encData, secretKey);
      return decryptedFromText.toString(CryptoJS.enc.Utf8);
    } else {
      return false;
    }
  }
  return false;
};

/*
 * Remove Item
 ***********************************************/
const removeItem = (key) => {
  if (typeof window !== "undefined") {
    localStorage.removeItem(`${prefix}_${key}`);
    localStorage.removeItem(`${prefix}_PROFILE`);
    return true;
  }
  return false;
};

/*
 * setEncryption
 ***********************************************/
const setEncryption = (payload) => {
  let encryptedData = CryptoJS.AES.encrypt(
    JSON.stringify(payload),
    secretKey
  ).toString();

  return encryptedData;
};

/*
 * setEncryption
 ***********************************************/
const setDecryption = (encdata) => {
  var decryptedFromText = CryptoJS.AES.decrypt(encdata, secretKey);
  return decryptedFromText.toString(CryptoJS.enc.Utf8);
};

export const EncryptionStorage = {
  setItem,
  getItem,
  removeItem,
  setEncryption,
  setDecryption,
};
