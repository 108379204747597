import { trim } from "lodash";

export const getIntakes = function(obj) {
  const intake = [
    {
      1: "January",
      2: "February",
      3: "March",
    },
    {
      4: "April",
      5: "May",
      6: "June",
    },
    {
      7: "July",
      8: "August",
      9: "September",
    },
    {
      10: "October",
      11: "November",
      12: "December",
    },
  ];

  // const month = [
  //   "January",
  //   "February",
  //   "March",
  //   "April",
  //   "May",
  //   "June",
  //   "July",
  //   "August",
  //   "September",
  //   "October",
  //   "November",
  //   "December",
  // ];

  const d = new Date();
  const currentMonth = d.getMonth();

  //current years intake
  let currentIntakes = [];
  let step = 0;
  for (let index = 0; index < 12; index++) {
    if (index >= currentMonth) {
      if (!currentIntakes.includes(intake[step])) {
        currentIntakes.push(intake[step]);
      }
    }
    if (index % 4 == 0) {
      step += 1;
    }
  }

  //nextyear Intake
  let NextIntake = [];
  let st = 0;
  for (let index = 0; index < 12; index++) {
    if (!NextIntake.includes(intake[st])) {
      NextIntake.push(intake[st]);
    }
    if (index % 4 == 0) {
      st += 1;
    }
  }

  return {
    [d.getFullYear()]: currentIntakes,
    [d.getFullYear() + 1]: NextIntake,
    [d.getFullYear() + 2]: NextIntake,
  };
};

export const intakeOptions = [
  { value: "", label: "Select Intake" },
  { value: "January-March", label: "January-March" },
  { value: "April-June", label: "April-June" },
  { value: "July-September", label: "July-September" },
  { value: "October-December", label: "October-December" },
];

export const scholarshipDedlines = [
  { value: "", label: "Select one" },
  { value: 1, label: "January" },
  { value: 2, label: "February" },
  { value: 3, label: "March" },
  { value: 4, label: "April" },
  { value: 5, label: "May" },
  { value: 6, label: "June" },
  { value: 7, label: "July" },
  { value: 8, label: "August" },
  { value: 9, label: "September" },
  { value: 10, label: "October" },
  { value: 11, label: "November" },
  { value: 12, label: "December" },
];

//export intake month and year
export const getIntakeYearMonth = function(obj) {
  if (!obj) return [];

  const months = [
    "January",
    "February",
    "March",

    "April",
    "May",
    "June",

    "July",
    "August",
    "September",

    "October",
    "November",
    "December",
  ];

  const d = new Date();
  const currentMonth = d.getMonth();
  let currentIntakes = [];

  obj.map((intake) => {
    if (currentMonth < months.indexOf(trim(intake.month)) + 1) {
      currentIntakes.push({ year: d.getFullYear(), month: intake.month });
    }
  });

  let nextYearIntake = [];
  obj.map((intake) => {
    if (currentMonth > months.indexOf(trim(intake.month)) + 1) {
      nextYearIntake.push({
        year: parseInt(d.getFullYear() + 1),
        month: intake.month,
      });
    }
  });

  return [...currentIntakes, ...nextYearIntake];
};

export const getExamPoint = (examId) => {
  let points = {
    1: { min: 4, max: 9 },
    2: { min: 200, max: 800 },
    3: { min: 130, max: 170 },
    4: { min: 200, max: 800 },
    5: { min: 40, max: 120 },
  };

  return points[parseInt(examId)] ?? "";
};

export const durations = [
  { value: "", label: "Select Duration" },
  { value: 6, label: "6 months" },
  { value: 12, label: "12 months" },
  { value: 18, label: "18 months" },
  { value: 24, label: "24 months" },
  { value: 30, label: "30 months" },
  { value: 36, label: "36 months" },
  { value: 48, label: "48 months" },
  { value: 54, label: "54 months" },
  { value: 60, label: "60 months" },
  { value: 66, label: "66 months" },
  { value: 72, label: "72 months" },
  { value: 78, label: "78 months" },
  { value: 84, label: "84 months" },
  { value: 90, label: "90 months" },
];

export const sessions = [
  { value: "", label: "Select Session" },
  { value: 1, label: "Summer" },
  { value: 2, label: "Fall" },
  { value: 3, label: "Spring" },
  { value: 4, label: "Winter" },
];

export const months = [
  { id: 1, value: "January", label: "January" },
  { id: 2, value: "February", label: "February" },
  { id: 3, value: "March", label: "March" },
  { id: 4, value: "April", label: "April" },
  { id: 5, value: "May", label: "May" },
  { id: 6, value: "June", label: "June" },
  { id: 7, value: "July", label: "July" },
  { id: 8, value: "August", label: "August" },
  { id: 9, value: "September", label: "September" },
  { id: 10, value: "October", label: "October" },
  { id: 11, value: "November", label: "November" },
  { id: 12, value: "December", label: "December" },
];

export const TuitionFor = [
  { value: "", label: "Select" },
  { value: "first_year", label: "1st Year" },
  { value: "second_year", label: "2nd Year" },
  { value: "third_year", label: "3rd Year" },
  { value: "fourth_year", label: "4th Year" },
];

//ranking Years
export const lastYears = (limit) => {
  const d = new Date();
  let year = d.getFullYear();
  let yearArray = [{ value: "", label: "Select Year" }];

  for (let i = 0; i < limit; i++) {
    yearArray.push({ value: parseInt(year - i), label: parseInt(year - i) });
  }

  return yearArray;
};

export const rankingYears = lastYears(5);

//Tution For

export const tutoionForOptions = [
  {
    label: "1st Year",
    value: "first_year",
  },
  {
    label: "2nd Year",
    value: "second_year",
  },
  {
    label: "3nd Year",
    value: "third_year",
  },
  {
    label: "4th Year",
    value: "fourth_year",
  },
];
