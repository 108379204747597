import { configureStore } from "@reduxjs/toolkit";
import { apiSlice } from "./apiSlice";
import eventPopupSlice from "./event/eventSlice";
import homePopupSlice from "./home/homeSlice";

export const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    eventPopup: eventPopupSlice,
    homePopup: homePopupSlice,
  },
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiSlice.middleware),
});
