import { atom } from "recoil";

const menuAtom = atom({
  key: "menu",
  default: {
    menuList: [],
  },
});

export { menuAtom };
