import { yupResolver } from "@hookform/resolvers/yup";
import { FormControlLabel } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import { authService } from "@unisearch/auth";
import { getAssets, getGeoInfo, swalError } from "@unisearch/helpers";
import Cookies from "js-cookie";
import Image from "next/legacy/image";
import Link from "next/link";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useForm } from "react-hook-form";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import * as Yup from "yup";
import styles from "./auth_signup.module.scss";

export const AuthSignup = ({ userTypeSlug }) => {
  const router = useRouter();
  const { ref } = router.query;
  if (ref !== undefined) {
    Cookies.set("ref", ref);
  }
  //loading
  const [loading, setLoading] = useState(false);
  const [mobile, setMobile] = useState();
  const refer = Cookies.get("ref");
  const referrer = Cookies.get("referrer");

  const [defaultCountryCode, setDefaultCountryCode] = useState("in");
  useEffect(() => {
    setTimeout(() => {
      if (Cookies.get("default_country")) {
        setDefaultCountryCode(Cookies.get("default_country"));
      }
    }, 1500);
  }, [defaultCountryCode]);

  // form validation rules
  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("First Name is required"),
    email: Yup.string()
      .email("Must be a valid email")
      .max(255)
      .required("Email is required"),
    password: Yup.string()
      .min(6, "Must be more than or equal 6 characters")
      .required("Password is required")
      .matches(
        /^(?=.*[0-9])(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])[a-zA-Z0-9!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]{6,}$/,
        "Must Contain 6 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
      ),
    recaptchaToken: Yup.string()
      .required("reCAPTCHA is required")
      .typeError("reCAPTCHA is required"),
    mobile: Yup.string()
      .required("Mobile number is required")
      .min(9, "Mobile number not valid"),
    passwordConfirmation: Yup.string().oneOf(
      [Yup.ref("password"), null],
      "Passwords must match"
    ),
  });

  const formOptions = {
    resolver: yupResolver(validationSchema),
    defaultValues: {
      tandc: false,
      userTypeSlug: userTypeSlug,
    },
    mode: "onChange",
  };

  // get functions to build form with useForm() hook
  const { register, handleSubmit, setError, formState, setValue } = useForm(
    formOptions
  );

  //handle mobile
  const handleChange = (value) => {
    setMobile(value);
    setValue("mobile", value);
  };

  const { isSubmitting } = formState;
  const { errors } = formState;

  const handleEmail = async (e) => {
    const { name, value } = e.target;

    await authService
      .checkExist({
        email: value,
        userTypeSlug: userTypeSlug,
      })
      .then((data) => {
        // console.log(data);
      })
      .catch((errors) => {
        //show error
        swalError(errors);

        //set email value
        setValue(name, undefined);

        //set error
        setError("email", {
          type: "manual",
          message: "Email address is not valid!",
        });
      });
  };

  function onSubmit({
    userTypeSlug,
    firstName,
    middleName,
    lastName,
    email,
    mobile,
    password,
    recaptchaToken,
    countryCode,
  }) {
    setLoading(true);
    //payload
    let payload = {
      userTypeSlug,
      firstName,
      middleName,
      lastName,
      email,
      mobile,
      password,
      countryCode,
      dialingCode: "",
      referralKey: refer ?? "",
      joiningSource: referrer ?? "",
      recaptchaToken,
    };
    authService
      .register(payload)
      .then((data) => {
        setLoading(false);
        const returnUrl = `/email-verification?type=${userTypeSlug}&email=${data}`;
        router.push(returnUrl);
      })
      .catch((errors) => {
        setLoading(false);
        swalError(errors, "Sorry!");
      });
  }

  //Handle Radio
  const handleRadio = (e) => {
    const { value } = e.target;
    if (value == "mentor") {
      router.push("/signup-mentor");
    } else {
      router.push("/signup-student");
    }
  };

  return (
    <section className={styles.section_auth}>
      <div className={styles.side_bg}></div>
      <div className="container">
        <div className={styles.wrapper}>
          <div className={styles.left_wrapper}>
            <div className={styles.logo_and_title_wrapper}>
              <Image
                src={getAssets("assets/logo-beta.webp")}
                alt="UniSearch Logo"
                blurDataURL={getAssets("assets/logo-beta.webp")}
                placeholder="blur"
                width={186}
                height={30}
              />
              <div className={styles.title}>
                Discover study abroad opportunities around the world with
                UniSearch
              </div>
            </div>
            <div className={styles.image_wrapper}>
              <img
                src={getAssets("assets/images/illustration_singin.png")}
                alt="singin"
              />
            </div>
          </div>
          <div className={styles.right_wrapper}>
            <div className={styles.form_title_wrapper}>
              <div className={styles.form_title}>Welcome To UniSearch</div>
              <div className={styles.form_sub_title}>
                <span>Already Have an Account? </span>
                <Link legacyBehavior href="/signin">
                  Sign In
                </Link>
              </div>
            </div>

            <div className={styles.form_wrapper}>
              <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                <div className={styles.type_wrapper}>
                  <label htmlFor="student">
                    <input
                      id="student"
                      name="userTypeSlug"
                      type="radio"
                      // disabled={userTypeSlug != "student" ? true : false}
                      defaultChecked={userTypeSlug == "student" ? true : false}
                      value={`student`}
                      onClick={handleRadio}
                    />
                    <div className={styles.content}>
                      <span className={styles.circle}></span>
                      <span>Student</span>
                    </div>
                  </label>

                  <label
                    htmlFor="mentor"
                    onClick={() => {
                      router.push("https://devmentor.myunisearch.com/signup");
                    }}
                  >
                    <input
                      id="mentor"
                      name="userTypeSlug"
                      type="radio"
                      // disabled={userTypeSlug != "mentor" ? true : false}
                      value={`mentor`}
                      defaultChecked={userTypeSlug != "student" ? true : false}
                      onClick={handleRadio}
                    />
                    <div className={styles.content}>
                      <span className={styles.circle}></span>
                      <span>Mentor</span>
                    </div>
                  </label>
                </div>

                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6} md={4}>
                    <div className={styles.form_group}>
                      <label>
                        First Name<span className={styles.important}>*</span>
                      </label>
                      <input
                        id="firstName"
                        name="firstName"
                        placeholder="First Name"
                        type="text"
                        {...register("firstName")}
                        className={`${styles.form_input}  ${
                          errors.firstName ? styles.error : ""
                        }`}
                      />
                      <div className={styles.error_msg}>
                        {errors.firstName?.message}
                      </div>
                    </div>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <div className={styles.form_group}>
                      <label>Middle Name</label>
                      <input
                        id="middleName"
                        name="middleName"
                        placeholder="Middle Name"
                        type="text"
                        {...register("middleName")}
                        className={`${styles.form_input}  ${
                          errors.middleName ? styles.error : ""
                        }`}
                      />
                    </div>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <div className={styles.form_group}>
                      <label>Last Name</label>
                      <input
                        id="lastName"
                        name="lastName"
                        placeholder="Last Name"
                        type="text"
                        {...register("lastName")}
                        className={`${styles.form_input}  ${
                          errors.lastName ? styles.error : ""
                        }`}
                      />
                    </div>
                  </Grid>

                  <Grid item xs={12} sm={6} md={6}>
                    <div className={styles.form_group}>
                      <label>
                        Email Address<span className={styles.important}>*</span>
                      </label>
                      <input
                        {...register("email")}
                        placeholder="Email Address"
                        name="email"
                        type="email"
                        // onBlur={handleEmail}
                        className={`${styles.form_input}  ${
                          errors.email ? styles.error : ""
                        }`}
                      />
                      <div className={styles.error_msg}>
                        {errors.email?.message}
                      </div>
                    </div>
                  </Grid>

                  <Grid item xs={12} sm={6} md={6}>
                    <div className={styles.form_group}>
                      <label className={styles.mobileLabel}>
                        Mobile Number<span className={styles.important}>*</span>
                      </label>
                      <PhoneInput
                        country={defaultCountryCode ?? "in"}
                        countryCodeEditable={false}
                        value={mobile}
                        className={`${styles.mobile_form_input}  ${
                          errors.mobile ? styles.error : ""
                        }`}
                        {...register("mobile")}
                        onChange={(_value, country, _event, formattedValue) => {
                          handleChange(formattedValue);
                          setValue("countryCode", country?.countryCode);
                          setError("mobile", {
                            type: "manual",
                            message:
                              _value.length >= 9
                                ? undefined
                                : "Mobile number not valid",
                          });
                        }}
                        name="mobile"
                      />
                      <div className={styles.error_msg}>
                        {errors.mobile?.message}
                      </div>
                    </div>
                  </Grid>

                  <Grid item xs={12} sm={6} md={6}>
                    <div className={styles.form_group}>
                      <label>
                        Password<span className={styles.important}>*</span>
                      </label>
                      <input
                        placeholder="Password"
                        name="password"
                        type="password"
                        {...register("password")}
                        className={`${styles.form_input}  ${
                          errors.password ? styles.error : ""
                        }`}
                      />
                      <div className={styles.error_msg}>
                        {errors.password?.message}
                      </div>
                    </div>
                  </Grid>

                  <Grid item xs={12} sm={6} md={6}>
                    <div className={styles.form_group}>
                      <label>
                        Confirm Password
                        <span className={styles.important}>*</span>
                      </label>
                      <input
                        placeholder="Confirm Password"
                        name="passwordConfirmation"
                        type="password"
                        {...register("passwordConfirmation")}
                        className={`${styles.form_input} ${
                          errors.passwordConfirmation ? styles.error : ""
                        }`}
                      />
                      <div className={styles.error_msg}>
                        {errors.passwordConfirmation?.message}
                      </div>
                    </div>
                  </Grid>
                </Grid>

                <div className={styles.terms_wrapper}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="tandc"
                        value={true}
                        {...register("tandc")}
                      />
                    }
                    label="I agree to the"
                  />

                  <Link legacyBehavior href="/terms-and-conditions">
                    <a className={styles.term_link}>Terms & Conditions</a>
                  </Link>

                  <div
                    className={`${styles.error_msg} ${styles.terms_con_error}`}
                  >
                    {errors.tandc?.message}
                  </div>
                </div>
                <ReCAPTCHA
                  sitekey="6Lc27gIiAAAAAM79MPfUbyHLZ8eNc01NBPKvpfR8"
                  onChange={(e) => {
                    setValue("recaptchaToken", e);
                    setError("recaptchaToken", {
                      type: "manual",
                      message: undefined,
                    });
                  }}
                />
                <div className={styles.error_msg}>
                  {errors.recaptchaToken?.message}
                </div>
                <div className={styles.btn_group_wrapper}>
                  <div className={styles.btn_social_group}>
                    <button
                      className="btn btn-bg btn-secondary"
                      type="submit"
                      disabled={loading}
                    >
                      {loading && (
                        <CircularProgress
                          size={15}
                          style={{ color: "white" }}
                        />
                      )}
                      Sign Up
                    </button>

                    {/* <button
                      type="button"
                      disabled={loading}
                      className={styles.google}
                    >
                      <div className={styles.icon}>
                        <AiFillGoogleCircle />
                      </div>
                    </button>
                    <button
                      disabled={loading}
                      type="submit"
                      className={`${styles.btn_social} ${styles.facebook}`}
                    >
                      <div className={styles.icon}>
                        <MdFacebook />
                      </div>
                    </button>

                    <button
                      type="button"
                      className={`${styles.btn_social} ${styles.twitter}`}
                      disabled={loading}
                    >
                      <div className={styles.icon}>
                        <AiFillTwitterCircle />
                      </div>
                    </button>
                     */}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AuthSignup;
