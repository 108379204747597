import { atom } from "recoil";

const evaluationAtom = atom({
  key: "evaluation",
  default: {
    levels: [],
    specializations: [],
    departments: [],
    countrylist: [],
    exams: [],
    objectives: [],
    educationLevels: [],

    is_loading: true,
    formStape: 1,
    show_next: false,
    show_prev: false,

    countryPreference: [],
    courseLevelId: undefined,
    specializationId: undefined,
    departmentIds: [],
    courseIntake: [],
    courseExam: [],
    highestEducation: {},
    objectiveId: undefined,

    regInfo: undefined,
    is_registered: false,
    studentId: 0,
  },
});

export { evaluationAtom };
