import React, { Fragment, useState, useEffect } from "react";
import { useRouter } from "next/router";
import Head from "next/head";
import styles from "./auth_signup.module.scss";

export const AuthWelcome = () => {
  const router = useRouter();

  const goProfileEvaluation = () => {
    //redirect welcome route
    router.push("/profile/evaluation");
  };

  return (
    <section className={styles.section_auth}>
      <Head>
        <title>Welcome for signup</title>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      </Head>

      <div className={styles.welcomeWrapper} style={{ minHeight: "260px" }}>
        <h1> Welcome for signup </h1>

        <button
          className={`btn btn-primary`}
          style={{ display: "inline-block" }}
          onClick={goProfileEvaluation}
        >
          Next
        </button>
      </div>
    </section>
  );
};

export default AuthWelcome;
