import { atom } from "recoil";

const courseAtom = atom({
  key: "course",
  default: {
    isLoading: false,
    listLoading: false,
    actionsLoading: false,
    lastError: false,

    courseLevel: [],
    heightEducationLevel: [],
    specializations: [],
    departments: [],
    prerequsits: [],
    exams: [],
    admissionSession: [],
    rankingCompany: [],
    rankingType: [],

    entities: [],
    paginationInfo: {},
    courseOverview: {},

    courseForEdit: {
      courseName: "",
      courseLevelId: undefined,
      specializationId: undefined,
      departmentId: undefined,
      courseDescription: "",
      courseRequirement: "",
      courseDuration: undefined,
      isTopListed: 0,
      courseDurationType: "month",
      applicationFee: undefined,
      additionalInfo: "",
      tuitionFee: [
        {
          tuitionFor: "",
          amountFrom: undefined,
          amountTo: undefined,
          numberOfCredit: undefined,
        },
      ],
      examRequiremnt: [],
      intake: [
        {
          admissionSessionId: undefined,
          month: undefined,
          monthTo: undefined,
        },
      ],
      coursePreReqMark: {
        courseLevelId: undefined,
        courseMark: undefined,
        preReqDescription: "",
      },
      status: "Draft",
      metaTitle: "",
      metaDescription: "",
      metaKeyword: "",
    },

    otherExpenses: [],
    courseRanking: [],

    mediaUpload: {
      featureImageId: undefined,
      featureImageSrc: "",
      prospectusFileId: undefined,
      prospectusFileSrc: "",
    },

    courseId: undefined,
    isTopListed: 0,
    formStape: 1,
    show_next: false,
    show_draft: false,
  },
});

export { courseAtom };
