import API from "@unisearch/helpers/api";

export const CurrencyConversion = async (code, convertedCode, value) => {
  const resp = await API.get(
    `/public/currency/convert/${code}/${convertedCode}/${value}`
  );

  if (!resp.error) {
    return resp.data.value;
  } else {
    return value;
  }
};
