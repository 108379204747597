import { atom } from "recoil";
import { EncryptionStorage } from "@unisearch/helpers";

const authAtom = atom({
  key: "auth",
  default:
    typeof window !== "undefined"
      ? JSON.parse(EncryptionStorage.getItem("PROFILE"))
      : "",
});

export { authAtom };
