import { yupResolver } from "@hookform/resolvers/yup";
import CircularProgress from "@mui/material/CircularProgress";
import { authService } from "@unisearch/auth";
import { getAssets, swalError } from "@unisearch/helpers";
import { useAuthActions } from "@_recoil/_actions";
import { systemAtom } from "@_recoil/_state";
import Image from "next/legacy/image";
import Link from "next/link";
import { useRouter } from "next/router";
import { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useForm } from "react-hook-form";
import { useRecoilValue } from "recoil";
import * as Yup from "yup";
import styles from "./auth_signin.module.scss";

export const AuthSignin = () => {
  //router
  const router = useRouter();
  const systemInfo = useRecoilValue(systemAtom);
  const authActions = useAuthActions();

  //signin for
  const signinFor = router.pathname == "/signin" ? "regular" : "partner";
  const [loginType, setLoginType] = useState(
    signinFor == "regular" ? "student" : "university"
  );
  //for loading state manage
  const [loading, setLoading] = useState(false);

  // form validation rules
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Must be a valid email")
      .max(255)
      .required("Email is required"),
    password: Yup.string().required("Password is required"),
    recaptchaToken: Yup.string()
      .required("reCAPTCHA is required")
      .typeError("reCAPTCHA is required"),
    userTypeSlug: Yup.string()
      .required("Select login User Type")
      .nullable()
      .oneOf(
        ["student", "mentor", "counsellor", "agent", "university"],
        "Select login User Type"
      ),
  });

  //form options
  const formOptions = {
    mode: "onChange",
    resolver: yupResolver(validationSchema),
    defaultValues: {},
    reValidateMode: "onChange",
  };

  // get functions to build form with useForm() hook
  const { register, handleSubmit, setError, setValue, formState } = useForm(
    formOptions
  );
  const { errors, value } = formState;
  function onSubmit({ email, password, userTypeSlug, recaptchaToken }) {
    setLoading(true);
    return authService
      .login({ email, password, userTypeSlug, recaptchaToken })
      .then(async (rsp) => {
        if (rsp && rsp.access_token) {
          //get profile info
          let userData = await authService.getProfile();

          //set user profile data
          authActions.setProfile(userData);

          if (userData.userTypeSlug && systemInfo.returnUrl) {
            router.push(systemInfo.returnUrl);
            return false;
          }

          //force ds
          if (userData.userTypeSlug) {
            const adminUrl = `/app/${userTypeSlug}`;
            router.push(adminUrl);
            return false;
          }

          setTimeout(() => {
            setLoading(false);
            return true;
          }, 10);
        } else {
          setLoading(false);
          swalError(rsp, "Sorry!");
          return false;
        }
      })
      .catch((errors) => {
        setLoading(false);
        swalError(errors, "Sorry!");
        return false;
      });
  }

  //handle radio
  const handleRadio = (e) => {
    const { value } = e.target;
    setLoginType(value);
  };

  return (
    <section className={styles.section_auth}>
      <div className={styles.side_bg}></div>
      <div className="container">
        <div className={styles.wrapper}>
          <div className={styles.left_wrapper}>
            <div className={styles.logo_and_title_wrapper}>
              <Image
                src={getAssets("assets/logo-beta.webp")}
                alt="UniSearch Logo"
                blurDataURL={getAssets("assets/logo-beta.webp")}
                placeholder="blur"
                width={186}
                height={30}
              />
              <div className={styles.title}>
                Discover study abroad opportunities around the world with
                UniSearch
              </div>
            </div>
            <div className={styles.image_wrapper}>
              <img
                src={getAssets("assets/images/illustration_singin.png")}
                alt="singin"
              />
            </div>
          </div>
          <div className={styles.right_wrapper}>
            <div className={styles.form_title_wrapper}>
              <div className={styles.form_title}>Welcome Back</div>
              {signinFor == "regular" && (
                <div className={styles.form_sub_title}>
                  <span>New Here? </span>
                  <Link
                    legacyBehavior
                    href={
                      loginType == "mentor"
                        ? "/signup-mentor"
                        : "/signup-student"
                    }
                  >
                    Create an Account
                  </Link>
                </div>
              )}
            </div>
            <div className={styles.form_wrapper}>
              <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                {signinFor && signinFor == "regular" && (
                  <div className={styles.type_wrapper_outside}>
                    <div className={styles.type_wrapper}>
                      <label htmlFor="student">
                        <input
                          id="student"
                          name="userTypeSlug"
                          type="radio"
                          {...register("userTypeSlug")}
                          value={"student"}
                          defaultChecked={true}
                          onClick={handleRadio}
                        />
                        <div
                          className={`${styles.content} ${
                            errors.userTypeSlug ? styles.error : ""
                          }`}
                        >
                          <span className={styles.circle}></span>
                          <span>Student</span>
                        </div>
                      </label>

                      <label
                        htmlFor="mentor"
                        onClick={() => {
                          router.push("https://devmentor.myunisearch.com");
                        }}
                      >
                        <input
                          id="mentor"
                          name="userTypeSlug"
                          type="radio"
                          {...register("userTypeSlug")}
                          value={"mentor"}
                          onClick={handleRadio}
                        />
                        <div
                          className={`${styles.content} ${
                            errors.userTypeSlug ? styles.error : ""
                          }`}
                        >
                          <span className={styles.circle}></span>
                          <span>Mentor</span>
                        </div>
                      </label>
                    </div>
                  </div>
                )}
                {signinFor && signinFor == "partner" && (
                  <div className={styles.type_wrapper_outside}>
                    <div className={styles.type_wrapper}>
                      {/* <label htmlFor="counsellor">
                        <input
                          id="counsellor"
                          name="userTypeSlug"
                          type="radio"
                          {...register("userTypeSlug")}
                          value={"counsellor"}
                          onClick={handleRadio}
                        />
                        <div
                          className={`${styles.content} ${
                            errors.userTypeSlug ? styles.error : ""
                          }`}
                        >
                          <span className={styles.circle}></span>
                          <span>Counsellor</span>
                        </div>
                      </label> */}
                      <label htmlFor="university">
                        <input
                          id="university"
                          name="userTypeSlug"
                          type="radio"
                          {...register("userTypeSlug")}
                          defaultChecked={true}
                          value={"university"}
                          onClick={handleRadio}
                        />
                        <div
                          className={`${styles.content} ${
                            errors.userTypeSlug ? styles.error : ""
                          }`}
                        >
                          <span className={styles.circle}></span>
                          <span>University</span>
                        </div>
                      </label>
                      <label htmlFor="agent">
                        <input
                          id="agent"
                          name="userTypeSlug"
                          type="radio"
                          {...register("userTypeSlug")}
                          value={"agent"}
                          onClick={handleRadio}
                        />
                        <div
                          className={`${styles.content} ${
                            errors.userTypeSlug ? styles.error : ""
                          }`}
                        >
                          <span className={styles.circle}></span>
                          <span>Agent</span>
                        </div>
                      </label>
                    </div>

                    <div className={styles.error_msg}>
                      {errors.userTypeSlug?.message}
                    </div>
                  </div>
                )}
                <div className={styles.form_group}>
                  <label>
                    Email <span className={styles.important}>*</span>
                  </label>
                  <input
                    placeholder="Type Your Email Address"
                    name="email"
                    type="email"
                    {...register("email")}
                    className={`${errors.email ? styles.error : ""}`}
                  />
                  <div className={styles.error_msg}>
                    {errors.email?.message}
                  </div>
                </div>
                <div className={styles.form_group}>
                  <label>
                    Password<span className={styles.important}>*</span>
                  </label>
                  <input
                    placeholder="Type Your Password"
                    name="password"
                    type="password"
                    {...register("password")}
                    autoComplete="off"
                    className={`${errors.password ? styles.error : ""}`}
                  />
                  <div className={styles.error_msg}>
                    {errors.password?.message}
                  </div>
                </div>
                <ReCAPTCHA
                  sitekey="6Lc27gIiAAAAAM79MPfUbyHLZ8eNc01NBPKvpfR8"
                  onChange={(e) => {
                    setValue("recaptchaToken", e);
                    setError("recaptchaToken", {
                      type: "manual",
                      message: undefined,
                    });
                  }}
                />
                <div className={styles.error_msg}>
                  {errors.recaptchaToken?.message}
                </div>
                <Link legacyBehavior href="/forgot-password">
                  <a className={styles.forget_password}>Forgot Password?</a>
                </Link>
                <div className={styles.btn_group_wrapper}>
                  <button
                    className="btn btn-bg btn-secondary"
                    type="submit"
                    disabled={loading}
                  >
                    {loading && (
                      <CircularProgress size="20px" style={{ color: "#fff" }} />
                    )}
                    Sign In
                  </button>

                  {signinFor && signinFor == "regular" && (
                    <div className={styles.btn_social_group}>
                      {/* <button type="button" className={styles.google}>
                        <div className={styles.icon}>
                          <AiFillGoogleCircle />
                        </div>
                         <span>Sign in with Google</span>  
                      </button>  

                      <button
                        type="button"
                        className={`${styles.btn_social} ${styles.facebook}`}
                      >
                        <div className={styles.icon}>
                          <MdFacebook />
                        </div>
                      </button>  
                      
                      <button
                        type="button"
                        className={`${styles.btn_social} ${styles.twitter}`}
                      >
                        <div className={styles.icon}>
                          <AiFillTwitterCircle />
                        </div>
                      </button> */}
                    </div>
                  )}
                </div>
                {errors.apiError && (
                  <div className="alert alert-danger mt-3 mb-0">
                    {errors.apiError?.message}
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AuthSignin;
