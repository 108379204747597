import axios from "axios";
import { EncryptionStorage } from "@unisearch/helpers";

const axiosInstance = axios.create({
  baseURL: process.env.API_URL,
});

axiosInstance.interceptors.request.use(
  (config) => {
    const authInfo = JSON.parse(EncryptionStorage.getItem("AUTH"));
    const { access_token } = authInfo;
    if (authInfo) {
      config.headers.Authorization = `Bearer ${access_token}`;
    }
    return config;
  },
  (err) => Promise.reject(err)
);

axiosInstance.interceptors.response.use(
  (response) => {
    if (response.status >= 200 && response.status <= 299) {
      return response.data;
    }
    return Promise.reject(response.data);
  },
  (error) => {
    const expErr =
      error?.response &&
      error?.response?.status >= 400 &&
      error?.response?.status < 500;
    if (error?.response?.status == 401) {
      EncryptionStorage.removeItem("AUTH");
    }

    if (!expErr) {
      console.log("Unexpected error : ", error.response);
    }
    if (error.response) {
      return error?.response?.data;
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
