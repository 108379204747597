import axios from "axios";
import { EncryptionStorage } from "@unisearch/helpers";

export const getGeoInfo = async () => {
  return new Promise(async (resolve, reject) => {
    let IP_INFO = JSON.parse(EncryptionStorage.getItem("IP"));

    if (IP_INFO && IP_INFO != "") {
      //return response
      resolve(IP_INFO);
    } else {
      await axios.get(`https://api.country.is`).then((rsp) => {
        if (rsp.data) {
          //set localstorage
          EncryptionStorage.setItem("IP", rsp.data);

          //return response
          resolve(rsp.data);
        } else {
          reject(false);
        }
      });
    }
  }).catch((err) => {
    return err;
  });
};
