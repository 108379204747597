import React, { useEffect } from "react";
import { getAssets } from "@unisearch/helpers";
import styles from "./new_password.module.scss";
import Image from "next/legacy/image";

export const NewPassword = () => {
  return (
    <section className={styles.section_auth}>
      <div className={styles.side_bg}></div>
      <div className="container">
        <div className={styles.wrapper}>
          <div className={styles.left_wrapper}>
            <div className={styles.logo_and_title_wrapper}>
              <Image
                src={getAssets("assets/logo-beta.webp")}
                alt="UniSearch Logo"
                blurDataURL={getAssets("assets/logo-beta.webp")}
                placeholder="blur"
                width={186}
                height={30}
              />
              <div className={styles.title}>
                Discover study abroad opportunities around the world with
                UniSearch
              </div>
            </div>
            <div className={styles.image_wrapper}>
              <img
                src={getAssets("assets/images/illustration_singin.png")}
                alt="singin"
              />
            </div>
          </div>

          <div className={styles.right_wrapper}>
            <div className={styles.form_title_wrapper}>
              <div className={styles.form_title}>Create New Password</div>
              <div className={styles.form_sub_title}>
                Your Password must be different from previously used passwords.
              </div>
            </div>

            <div className={styles.form_wrapper}>
              <form>
                <div className={styles.form_group}>
                  <label>New Password</label>
                  <input placeholder="Type New Password" type="password" />
                </div>
                <div className={styles.form_group}>
                  <label>Confirm Password</label>
                  <input
                    placeholder="Type Your Password Again"
                    type="password"
                  />
                </div>

                <div className={styles.btn_group_wrapper}>
                  <button className="btn btn-bg btn-secondary" type="submit">
                    Create
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NewPassword;
