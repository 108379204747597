import { useSetRecoilState, useRecoilValue } from "recoil";
import API from "@unisearch/helpers/api";

import { courseAtom } from "@_recoil/_state";

export { useCourseActions };

function useCourseActions() {
  //set actions
  const setCourses = useSetRecoilState(courseAtom);
  const courseState = useRecoilValue(courseAtom);

  //find all courses
  const findCourses = async (payload) => {
    //set loading
    setCourses((curVal) => ({
      ...curVal,
      listLoading: true,
    }));

    //fetch  Course Level
    await API.post(`/university/university-course/findcourses`, payload).then(
      (resp) => {
        if (!resp.error) {
          const data = resp.data;
          const pager = {
            currentPage: data?.currentPage,
            limit: data?.limit,
            nextPage: data?.nextPage,
            page_total: data?.page_total,
            previousPage: data?.previousPage,
            totalRows: data?.total,
          };

          setCourses((prev) => ({
            ...prev,
            entities: data.results ?? [],
            paginationInfo: pager,
            isLoading: false,
            courseId: false,
          }));
        } else {
          setCourses((prev) => ({
            ...prev,
            isLoading: false,
            lastError: resp.data,
            paginationInfo: {},
            entities: [],
            courseId: false,
          }));
        }
      }
    );
    //set loading
    setCourses((curVal) => ({
      ...curVal,
      listLoading: false,
    }));
  };

  //find all courses
  const findTopCourses = async (payload) => {
    //set loading
    setCourses((curVal) => ({
      ...curVal,
      listLoading: true,
    }));

    //fetch  Course Level
    await API.post(`/university/university-course/findcourses`, payload).then(
      (resp) => {
        if (!resp.error) {
          const data = resp.data;
          const pager = {
            currentPage: data?.currentPage,
            limit: data?.limit,
            nextPage: data?.nextPage,
            page_total: data?.page_total,
            previousPage: data?.previousPage,
            totalRows: data?.total,
          };

          setCourses((prev) => ({
            ...prev,
            entities: data.results ?? [],
            paginationInfo: pager,
            isLoading: false,
            courseId: false,
          }));
        } else {
          setCourses((prev) => ({
            ...prev,
            isLoading: false,
            lastError: resp.data,
            paginationInfo: {},
            entities: [],
            courseId: false,
          }));
        }
      }
    );
    //set loading
    setCourses((curVal) => ({
      ...curVal,
      listLoading: false,
    }));
  };

  //get all courses overview
  const getStatusOverview = async (payload) => {
    //set loading
    setCourses((prev) => ({
      ...prev,
      isLoading: true,
    }));

    //fetch  Course Level
    await API.get(`/university/university-course/status-overview`).then(
      (resp) => {
        if (!resp.error) {
          setCourses((prev) => ({
            ...prev,
            courseOverview: resp.data ?? {},
            isLoading: false,
          }));
        } else {
          setCourses((prev) => ({
            ...prev,
            courseOverview: {},
            isLoading: false,
          }));
        }
      }
    );
  };

  //get Course Levels
  const getCourseLevels = async () => {
    //set loading
    setCourses((curVal) => ({
      ...curVal,
      isLoading: true,
    }));

    //fetch  Course Level
    await API.get(`/public/course-level`).then((resp) => {
      if (resp.data) {
        const courseLevel = resp.data.map((item) => {
          return {
            label: item.levelName,
            value: item.levelId,
          };
        });
        setCourses((pVal) => ({
          ...pVal,
          courseLevel: courseLevel,
          isLoading: false,
        }));
      }
    });
  };

  //get Height Education Levels
  const getheightEduLevels = async () => {
    //set loading
    setCourses((curVal) => ({
      ...curVal,
      isLoading: true,
    }));

    //fetch  Course Level
    await API.get(`/public/study/highest-level-education/dropdown`).then(
      (resp) => {
        if (!resp.error && resp.data) {
          setCourses((pVal) => ({
            ...pVal,
            heightEducationLevel: resp.data,
            isLoading: false,
          }));
        }
      }
    );
  };

  //get Course Levels
  const getPrerequisites = async (id) => {
    //set loading
    setCourses((curVal) => ({
      ...curVal,
      isLoading: true,
    }));

    //fetch  Course Level
    await API.get(`/public/education/${id}`).then((resp) => {
      if (!resp.error) {
        const prerequsits = resp.data.map((item) => {
          return {
            label: item.levelName,
            value: item.levelId,
          };
        });

        setCourses((pVal) => ({
          ...pVal,
          prerequsits: prerequsits,
          isLoading: false,
        }));
      }
    });
  };

  //get Specializations
  const getDepartments = async () => {
    //set loading
    setCourses((prev) => ({
      ...prev,
      is_loading: true,
    }));

    //fetch specializtion
    await API.get(`/public/specialization`).then((resp) => {
      if (resp.data) {
        const spdata = resp.data.map((item) => {
          return {
            label: item.specializationName,
            value: item.specializationId,
          };
        });
        setCourses((prev) => ({
          ...prev,
          departments: spdata,
          is_loading: false,
        }));
      }
    });
  };

  //get Specializations
  const getSpecializations = async (id) => {
    //set loading
    setCourses((prev) => ({
      ...prev,
      is_loading: true,
    }));

    //fetch specializtion
    await API.get(`/public/department/${id}`).then((resp) => {
      if (resp.data) {
        const spdata = resp.data.map((item) => {
          return {
            label: item.specializationName,
            value: item.specializationId,
          };
        });
        setCourses((prev) => ({
          ...prev,
          specializations: spdata,
          is_loading: false,
        }));
      }
    });
  };

  //get Sub Specializations
  const getSubSpecializations = async () => {
    //set loading
    setCourses((prev) => ({
      ...prev,
      is_loading: true,
    }));

    return new Promise(async (resolve, reject) => {
      //get sub specializations
      await API.get(`/public/department/${id}`).then((rsp) => {
        setCourses((prev) => ({
          ...prev,
          is_loading: false,
        }));

        if (rsp.error) {
          reject(rsp.message);
        }

        if (rsp.data) {
          setCourses((prev) => ({
            ...prev,
            departments: rsp.data,
          }));

          resolve(rsp.data);
        } else {
          reject(rsp.message);
        }
      });
    });
  };

  //get Admission Sessions
  const getAdmissionSession = async () => {
    //set loading
    setCourses((prev) => ({
      ...prev,
      is_loading: true,
    }));

    //fetch specializtion
    await API.get(`/study/admission-session/dropdown`).then((resp) => {
      if (resp.data) {
        setCourses((prev) => ({
          ...prev,
          admissionSession: resp.data,
          is_loading: false,
        }));
      } else {
        setCourses((prev) => ({
          ...prev,
          admissionSession: [],
          is_loading: false,
        }));
      }
    });
  };

  //get all exams
  const getExams = async () => {
    //set loading
    setCourses((prev) => ({
      ...prev,
      is_loading: true,
    }));

    await API.get(`/public/exam`).then((resp) => {
      if (resp.data) {
        setCourses((prev) => ({
          ...prev,
          exams: resp.data,
          is_loading: false,
        }));
      }
    });
  };

  //get all exams
  const rankingCompanies = async () => {
    //set loading
    setCourses((prev) => ({
      ...prev,
      is_loading: true,
    }));

    await API.get(`/admin/ranking-company/dropdown`).then((resp) => {
      if (resp.data) {
        setCourses((prev) => ({
          ...prev,
          rankingCompanies: resp.data,
          is_loading: false,
        }));
      }
    });
  };

  //get Course Levels
  const getSingleCourse = async (id) => {
    //set loading
    setCourses((prev) => ({
      ...prev,
      isLoading: true,
    }));

    //fetch  Course Level
    await API.get(`/university/university-course/${id}`).then((resp) => {
      if (!resp.error) {
        const result = resp.data;

        let courseData = {
          status: result.status,
          courseId:
            result.courseId && result.courseId != null ? result.courseId : "",
          isTopListed:
            result.isTopListed && result.isTopListed != null
              ? result.isTopListed
              : 0,
          universityId:
            result.universityId && result.universityId != null
              ? result.universityId
              : "",
          courseName:
            result.courseName && result.courseName != null
              ? result.courseName
              : "",
          courseLevelId:
            result.courseLevelId && result.courseLevelId != null
              ? result.courseLevelId
              : "",
          courseDescription:
            result.courseDescription && result.courseDescription != null
              ? result.courseDescription
              : "",
          courseRequirement:
            result.courseRequirement && result.courseRequirement != null
              ? result.courseRequirement
              : "",
          courseDuration:
            result.courseDuration && result.courseDuration != null
              ? result.courseDuration
              : "",

          courseDurationType:
            result.courseDurationType && result.courseDurationType != null
              ? result.courseDurationType
              : "",
          applicationFee:
            result.applicationFee && result.applicationFee != null
              ? result.applicationFee
              : "",
          additionalInfo:
            result.additionalInfo && result.additionalInfo != null
              ? result.additionalInfo
              : "",
          featureImageId:
            result.featureImageId && result.featureImageId != null
              ? result.featureImageId
              : "",
          prospectusFileId:
            result.prospectusFileId && result.prospectusFileId != null
              ? result.prospectusFileId
              : "",
          featureImage:
            result.featureImage && result.featureImage != null
              ? result.featureImage
              : "",
          prospectusFile:
            result.prospectusFile && result.prospectusFile != null
              ? result.prospectusFile
              : "",
          isTopListed: result.isTopListed == 1 ? ["1"] : ["0"],
          coursePreReqMark: {
            courseLevelId:
              result.coursePreReqMark.courseLevelId &&
              result.coursePreReqMark.courseLevelId != null
                ? result.coursePreReqMark.courseLevelId
                : "",
            courseMark:
              result.coursePreReqMark.courseMark &&
              result.coursePreReqMark.courseMark != null
                ? result.coursePreReqMark.courseMark
                : "",
            preReqDescription:
              result.coursePreReqMark.preReqDescription &&
              result.coursePreReqMark.preReqDescription != null
                ? result.coursePreReqMark.preReqDescription
                : "",
          },
        };
        let specializationId = undefined;
        let departmentId = undefined;
        if (result.specialization && result.specialization.parentId !== "0") {
          specializationId = parseInt(result.specialization.specializationId);
          departmentId = parseInt(result.specialization.parentId);
        } else {
          specializationId = undefined;
          departmentId = parseInt(result.specialization.specializationId);
        }

        //tution Fees
        const tuitionFee = [];
        if (result.tuitionFee && result.tuitionFee.length) {
          result.tuitionFee.map((item) => {
            let tution = {
              tuitionFor:
                item.tuitionFor && item.tuitionFor != null
                  ? item.tuitionFor
                  : "",
              tuitionFeeId:
                item.tuitionFeeId && item.tuitionFeeId != null
                  ? item.tuitionFeeId
                  : "",
              amountFrom:
                item.amountFrom && item.amountFrom != null
                  ? item.amountFrom
                  : "",
              amountTo:
                item.amountTo && item.amountTo != null ? item.amountTo : "",
              numberOfCredit:
                item.numberOfCredit && item.numberOfCredit != null
                  ? item.numberOfCredit
                  : "",
            };
            tuitionFee.push(tution);
          });
        }

        //livingCost
        const livingCost = [];
        if (result.livingCost && result.livingCost.length > 0) {
          result.livingCost.map((item) => {
            let lcost = {
              livingCostType:
                item.livingCostType && item.livingCostType != null
                  ? item.livingCostType
                  : "",

              valueFrom:
                item.valueFrom && item.valueFrom != null ? item.valueFrom : "",

              valueTo: item.valueTo && item.valueTo != null ? item.valueTo : "",
            };
            livingCost.push(lcost);
          });
        }

        //examRequiremnt
        const examRequiremnt = [];
        if (result.examRequiremnt && result.examRequiremnt.length > 0) {
          result.examRequiremnt.map((item) => {
            let examreq = {
              examId: item.examId && item.examId != null ? item.examId : "",
              score: item.score && item.score != null ? item.score : "",
              summary: item.summary && item.summary != null ? item.summary : "",
            };
            examRequiremnt.push(examreq);
          });
        }

        //intake
        const intake = [];
        if (result.intake && result.intake.length > 0) {
          result.intake.map((item) => {
            let intakeData = {
              month: item.month && item.month != null ? item.month : "",
              monthTo: item.monthTo && item.monthTo != null ? item.monthTo : "",
              admissionSessionId:
                item.admissionSessionId && item.admissionSessionId != null
                  ? item.admissionSessionId
                  : "",
            };
            intake.push(intakeData);
          });
        }
        //courseRanking
        const courseRanking = [];
        if (result.courseRanking && result.courseRanking.length > 0) {
          result.courseRanking.map((item) => {
            let intakeData = {
              courseRankFrom:
                item.courseRankFrom && item.courseRankFrom != null
                  ? item.courseRankFrom
                  : "",
              courseRankTo:
                item.courseRankTo && item.courseRankTo != null
                  ? item.courseRankTo
                  : "",
              courseRankingYear:
                item.courseRankingYear && item.courseRankingYear != null
                  ? item.courseRankingYear
                  : "",
              rankingCompanyId:
                item.rankingCompanyId && item.rankingCompanyId != null
                  ? item.rankingCompanyId
                  : "",
              rankingType:
                item.rankingType && item.rankingType != null
                  ? item.rankingType
                  : "",
              rankingVarying:
                item.rankingVarying && item.rankingVarying != null
                  ? item.rankingVarying
                  : "",
            };
            courseRanking.push(intakeData);
          });
        }

        const courseForEdit = {
          ...courseData,
          tuitionFee,
          livingCost,
          examRequiremnt,
          intake,
          courseRanking,
          specializationId,
          departmentId,
        };

        setCourses((prev) => ({
          ...prev,
          courseForEdit: courseForEdit,
          course_id: id,
          isLoading: false,
        }));

        //get prerequisit
        if (result.courseLevelId) {
          getPrerequisites(result.courseLevelId);
        }
      }
    });

    setCourses((curVal) => ({
      ...curVal,
      isLoading: false,
    }));
  };

  //Soft Delete Items
  const softDelete = async (ids) => {
    //set loading
    setCourses((prev) => ({
      ...prev,
      actionsLoading: true,
    }));

    //soft delete item
    await API.patch(`/university/university-course/change-status`, {
      ids: ids,
      status: "Deleted",
    }).then((resp) => {
      if (!resp.error) {
        console.log("success");
      }
    });

    setCourses((prev) => ({
      ...prev,
      actionsLoading: false,
    }));
  };

  //Hard Delete items
  const hardDelete = async (id) => {
    //set loading
    setCourses((prev) => ({
      ...prev,
      actionsLoading: true,
    }));

    //hard delete item
    await API.patch(`/university/university-course/hard-delete`, {
      ids: id,
    }).then((resp) => {
      if (!resp.error) {
        console.log("success");
      }
    });

    setCourses((prev) => ({
      ...prev,
      actionsLoading: false,
    }));
  };

  //Soft Delete Items
  const itemsRestore = async (ids) => {
    //set loading
    setCourses((prev) => ({
      ...prev,
      actionsLoading: true,
    }));

    //soft delete item
    await API.patch(`/university/university-course/change-status`, {
      ids: ids,
      status: "Draft",
    }).then((resp) => {
      if (!resp.error) {
        console.log("success");
      }
    });

    setCourses((prev) => ({
      ...prev,
      actionsLoading: false,
    }));
  };

  //clear basic info
  const clearBasicInfo = () => {
    setCourses((prev) => ({
      ...prev,
      courseForEdit: {
        courseName: "",
        courseLevelId: undefined,
        specializationId: undefined,
        courseDescription: "",
        courseRequirement: "",
        courseDuration: undefined,
        isTopListed: 0,
        courseDurationType: "month",
        applicationFee: undefined,
        additionalInfo: "",
        tuitionFee: [
          {
            tuitionFor: "",
            amountFrom: undefined,
            amountTo: undefined,
            numberOfCredit: undefined,
          },
        ],
        examRequiremnt: [],
        intake: [
          {
            admissionSessionId: undefined,
            month: undefined,
            monthTo: undefined,
          },
        ],
        coursePreReqMark: {
          courseLevelId: undefined,
          courseMark: undefined,
          preReqDescription: "",
        },
        status: "Draft",
        metaTitle: "",
        metaDescription: "",
        metaKeyword: "",
      },
    }));
  };

  //clear State
  const clearState = async () => {
    setCourses((prev) => ({
      ...prev,

      isLoading: false,
      listLoading: false,
      actionsLoading: false,
      lastError: false,

      prerequsits: [],

      courseRanking: [],

      courseForEdit: {
        courseName: "",
        courseLevelId: undefined,
        specializationId: undefined,
        courseDescription: "",
        courseRequirement: "",
        courseDuration: undefined,
        isTopListed: 0,
        courseDurationType: "month",
        applicationFee: undefined,
        additionalInfo: "",
        tuitionFee: [
          {
            tuitionFor: "",
            amountFrom: undefined,
            amountTo: undefined,
            numberOfCredit: undefined,
          },
        ],
        examRequiremnt: [],
        intake: [
          {
            admissionSessionId: undefined,
            month: undefined,
            monthTo: undefined,
          },
        ],
        coursePreReqMark: {
          courseLevelId: undefined,
          courseMark: undefined,
          preReqDescription: "",
        },
        status: "Draft",
        metaTitle: "",
        metaDescription: "",
        metaKeyword: "",
      },

      otherExpenses: [],

      courseRanking: [],

      mediaUpload: {
        featureImageId: undefined,
        prospectusFileId: undefined,
      },

      courseId: undefined,
      isTopListed: 0,
      formStape: 1,
      show_next: false,
      show_draft: false,
    }));
  };

  //storeBasicInfo
  const storeBasicInfo = async (values) => {
    //set loading
    setCourses((prev) => ({
      ...prev,
      actionsLoading: true,
    }));

    //store basic information
    return await API.post(
      "/university/university-course/basic-info",
      values
    ).then((res) => {
      //set loading
      setCourses((prev) => ({
        ...prev,
        actionsLoading: false,
      }));
      return res;
    });
  };

  //Update BasicInfo
  const updateBasicInfo = async (values, id) => {
    //set loading
    setCourses((prev) => ({
      ...prev,
      actionsLoading: true,
    }));

    //store basic information
    return await API.patch(`/university/university-course/${id}`, values).then(
      (res) => {
        //set loading
        setCourses((prev) => ({
          ...prev,
          actionsLoading: false,
        }));
        return res;
      }
    );
  };

  //get course Basic Information
  const getCourseBasicInfo = async (id) => {
    //set loading
    setCourses((prev) => ({
      ...prev,
      isLoading: true,
    }));

    //fetch  Course Level
    await API.get(`/university/university-course/${id}`).then((resp) => {
      if (!resp.error) {
        const result = resp.data;

        let courseData = {
          courseName:
            result.courseName && result.courseName != null
              ? result.courseName
              : "",

          courseLevelId:
            result.courseLevelId && result.courseLevelId != null
              ? result.courseLevelId
              : undefined,

          courseDescription:
            result.courseDescription && result.courseDescription != null
              ? result.courseDescription
              : "",

          courseRequirement:
            result.courseRequirement && result.courseRequirement != null
              ? result.courseRequirement
              : "",

          courseDuration:
            result.courseDuration && result.courseDuration != null
              ? result.courseDuration
              : "",

          courseDurationType:
            result.courseDurationType && result.courseDurationType != null
              ? result.courseDurationType
              : "",

          isTopListed:
            result.isTopListed && result.isTopListed != null
              ? result.isTopListed
              : "",

          applicationFee:
            result.applicationFee && result.applicationFee != null
              ? parseInt(result.applicationFee)
              : "",

          additionalInfo:
            result.additionalInfo && result.additionalInfo != null
              ? result.additionalInfo
              : "",

          status: result.status && result.status != null ? result.status : "",
          metaTitle:
            result.metaTitle && result.metaTitle != null
              ? result.metaTitle
              : "",
          metaDescription:
            result.metaDescription && result.metaDescription != null
              ? result.metaDescription
              : "",
          metaKeyword:
            result.metaKeyword && result.metaKeyword != null
              ? result.metaKeyword
              : "",

          coursePreReqMark: {
            courseLevelId:
              result.coursePreReqMark.courseLevelId &&
              result.coursePreReqMark.courseLevelId != null
                ? result.coursePreReqMark.courseLevelId
                : "",
            courseMark:
              result.coursePreReqMark.courseMark &&
              result.coursePreReqMark.courseMark != null
                ? result.coursePreReqMark.courseMark
                : "",
            preReqDescription:
              result.coursePreReqMark.preReqDescription &&
              result.coursePreReqMark.preReqDescription != null
                ? result.coursePreReqMark.preReqDescription
                : "",
          },
        };
        let specializationId = undefined;
        let departmentId = undefined;
        if (result.specialization && result.specialization.parentId !== "0") {
          specializationId = parseInt(result.specialization.specializationId);
          departmentId = parseInt(result.specialization.parentId);
        } else {
          specializationId = undefined;
          departmentId = parseInt(result.specialization.specializationId);
        }
        //tution Fees
        const tuitionFee = [];
        if (result.tuitionFee && result.tuitionFee.length) {
          result.tuitionFee.map((item) => {
            let tution = {
              tuitionFor:
                item.tuitionFor && item.tuitionFor != null
                  ? item.tuitionFor
                  : "",
              tuitionFeeId:
                item.tuitionFeeId && item.tuitionFeeId != null
                  ? parseInt(item.tuitionFeeId)
                  : "",
              amountFrom:
                item.amountFrom && item.amountFrom != null
                  ? parseFloat(item.amountFrom)
                  : undefined,
              amountTo:
                item.amountTo && item.amountTo != null && item.amountTo > 0
                  ? parseFloat(item.amountTo)
                  : undefined,

              numberOfCredit:
                item.numberOfCredit && item.numberOfCredit != null
                  ? parseInt(item.numberOfCredit)
                  : "",
            };
            tuitionFee.push(tution);
          });
        }

        //examRequiremnt
        const examRequiremnt = [];
        if (result.examRequiremnt && result.examRequiremnt.length > 0) {
          result.examRequiremnt.map((item) => {
            let examreq = {
              examId: item.examId && item.examId != null ? item.examId : "",
              score: item.score && item.score != null ? item.score : "",
              summary: item.summary && item.summary != null ? item.summary : "",
            };
            examRequiremnt.push(examreq);
          });
        }

        //intake
        const intake = [];
        if (result.intake && result.intake.length > 0) {
          result.intake.map((item) => {
            let intakeData = {
              intakeId:
                item.intakeId && item.intakeId != null
                  ? parseInt(item.intakeId)
                  : "",
              month: item.month && item.month != null ? item.month : "",
              admissionSessionId:
                item.admissionSessionId && item.admissionSessionId != null
                  ? item.admissionSessionId
                  : "",
            };
            intake.push(intakeData);
          });
        }

        const courseForEdit = {
          ...courseData,
          tuitionFee,
          examRequiremnt,
          intake,
          specializationId,
          departmentId,
        };

        setCourses((prev) => ({
          ...prev,
          courseForEdit: courseForEdit,
          otherExpenses: [],
          courseRanking: [],
          courseId: id,
          isLoading: false,
        }));

        //get prerequisit
        if (result.courseLevelId) {
          getPrerequisites(result.courseLevelId);
        }
      }
    });

    setCourses((curVal) => ({
      ...curVal,
      isLoading: false,
    }));
  };

  //get Other Expenses
  const getOtherExpenses = async (id) => {
    //set loading
    setCourses((prev) => ({
      ...prev,
      actionsLoading: true,
    }));

    //fetch  Course Level
    await API.get(`/university/university-course/other-expense/${id}`).then(
      (resp) => {
        if (!resp.error && resp.statusCode == 200) {
          if (resp.data && resp.data.length > 0) {
            let data = [];

            resp.data.map((item) => {
              data.push({
                livingCostType: item?.livingCostType,
                livingCostId: parseInt(item?.livingCostId),
                valueFrom: item?.valueFrom,
                valueTo: item?.valueTo,
              });
            });
            setCourses((prev) => ({
              ...prev,
              otherExpenses: data,
            }));
          } else {
            setCourses((prev) => ({
              ...prev,
              otherExpenses: [],
            }));
          }
        }
      }
    );

    setCourses((curVal) => ({
      ...curVal,
      actionsLoading: false,
    }));
  };

  //update Course Others Expenses
  const updateOthersExpnense = async (values, id) => {
    delete values.formStape;
    const valuesPre = values.othersExpense.map((item) => {
      if (parseFloat(item.valueTo) > 0) {
        return item;
      } else {
        return {
          livingCostType: item.livingCostType,
          valueFrom: parseFloat(item.valueFrom),
        };
      }
    });
    return await API.patch(
      `/university/university-course/other-expense/${id}`,
      valuesPre
    ).then((res) => {
      return res;
    });

    //store basic information
    // if (values.length > 0) {
    //   return await API.patch(
    //     `/university/university-course/other-expense/${id}`,
    //     valuesPre
    //   ).then((res) => {
    //     return res;
    //   });
    // } else {
    //   return await API.delete(
    //     `/university/university-course/other-expense/${id}`,
    //     valuesPre
    //   ).then((res) => {
    //     return res;
    //   });
    // }
  };
  const deleteOthersExpense = async (id) => {
    //set loading
    setCourses((prev) => ({
      ...prev,
      actionsLoading: true,
    }));

    //soft delete item
    await API.delete(`/university/university-course/other-expense/${id}`).then(
      (resp) => {
        if (!resp.error) {
          console.log("success");
        }
      }
    );

    setCourses((prev) => ({
      ...prev,
      actionsLoading: false,
    }));
  };
  //get Course Ranking information
  const getCourseRanking = async (id) => {
    //set loading
    setCourses((prev) => ({
      ...prev,
      actionsLoading: true,
    }));

    //fetch  Course Level
    await API.get(`/university/course-ranking/course/${id}`).then((resp) => {
      if (!resp.error) {
        if (resp.data && resp.data.length > 0) {
          setCourses((prev) => ({
            ...prev,
            courseRanking: resp.data,
          }));
        } else {
          setCourses((prev) => ({
            ...prev,
            courseRanking: [],
          }));
        }
      }
    });

    setCourses((prev) => ({
      ...prev,
      actionsLoading: false,
    }));
  };

  //get Course Ranking information
  const getRankingCompanies = async () => {
    await API.get(`/public/ranking-company/dropdown`).then((resp) => {
      if (!resp.error) {
        const result = resp.data;
        if (result.length > 0) {
          setCourses((prev) => ({
            ...prev,
            rankingCompany: result,
          }));
        } else {
          setCourses((prev) => ({
            ...prev,
            rankingCompany: [],
          }));
        }
      }
    });
  };

  //get Course Ranking information
  const getRankingTypes = async () => {
    await API.get(`/public/ranking-company/ranking-type-dropdown`).then(
      (resp) => {
        if (!resp.error && resp.statusCode == 200) {
          const result = resp.data;
          if (result.length > 0) {
            setCourses((prev) => ({
              ...prev,
              rankingType: result,
            }));
          } else {
            setCourses((prev) => ({
              ...prev,
              rankingType: [],
            }));
          }
        }
      }
    );
  };

  //update Course Others Expenses
  const updateCourseRanking = async (values, rankingId) => {
    //set loading
    setCourses((prev) => ({
      ...prev,
      actionsLoading: true,
    }));

    return await API.patch(
      `university/course-ranking/${rankingId}`,
      values
    ).then((res) => {
      //set loading
      setCourses((prev) => ({
        ...prev,
        actionsLoading: false,
      }));

      return res;
    });

    //set loading
    setCourses((prev) => ({
      ...prev,
      actionsLoading: false,
    }));
  };

  //update Course Others Expenses
  const createCourseRanking = async (values) => {
    //set loading
    setCourses((prev) => ({
      ...prev,
      actionsLoading: true,
    }));

    //store basic information
    return await API.post(`/university/course-ranking`, values).then((res) => {
      //set loading
      setCourses((prev) => ({
        ...prev,
        actionsLoading: false,
      }));
      return res;
    });

    //set loading
    setCourses((prev) => ({
      ...prev,
      actionsLoading: false,
    }));
  };

  //Delete course ranking information
  const removeCourseRanking = async (id) => {
    return await API.delete(`university/course-ranking/${id}`).then((res) => {
      return res;
    });
  };

  //stor Media information
  const storeMediaInfo = async (values, courseId) => {
    //set loading
    setCourses((prev) => ({
      ...prev,
      actionsLoading: true,
    }));
    return await API.post(
      `/university/university-course/media-upload/${courseId}`,
      values
    ).then((res) => {
      //set loading
      setCourses((prev) => ({
        ...prev,
        actionsLoading: false,
      }));
      return res;
    });

    //set loading
    setCourses((prev) => ({
      ...prev,
      actionsLoading: false,
    }));
  };

  //get Course Ranking information
  const getMedia = async (courseId) => {
    await API.get(
      `/university/university-course/media-upload/${courseId}`
    ).then((resp) => {
      if (!resp.error && resp.statusCode == 200) {
        setCourses((prev) => ({
          ...prev,
          mediaUpload: resp.data,
        }));
      } else {
        setCourses((prev) => ({
          ...prev,
          mediaUpload: {
            featureImageId: undefined,
            prospectusFileId: undefined,
          },
        }));
      }
    });
  };

  //set top courses
  const setTopListed = async (courseId, values) => {
    return await API.patch(
      `/university/university-course/update-popular-course/${courseId}`,
      values
    ).then((res) => {
      if (!res.error && res.statusCode == 200) {
        const entities = [...courseState.entities];
        const index = entities.findIndex((item) => item.courseId == courseId);
        entities[index] = {
          ...entities[index],
          isTopListed: res?.data?.isTopListed ?? 0,
        };
        setCourses((prev) => ({
          ...prev,
          entities,
        }));
        return true;
      } else {
        return false;
      }
    });
  };
  //Update Intake
  const updateIntakeInfo = async (values, id) => {
    //set loading
    setCourses((prev) => ({
      ...prev,
      actionsLoading: true,
    }));

    //store basic information
    return await API.patch(
      `/university/university-course/update-course-intake/${id}`,
      values
    ).then((res) => {
      //set loading
      setCourses((prev) => ({
        ...prev,
        actionsLoading: false,
      }));
      return res;
    });
  };
  //Update Intake
  const updateFeeInfo = async (values, id) => {
    //set loading
    setCourses((prev) => ({
      ...prev,
      actionsLoading: true,
    }));

    //store basic information
    return await API.patch(
      `/university/university-course/update-tuition-fees/${id}`,
      values
    ).then((res) => {
      //set loading
      setCourses((prev) => ({
        ...prev,
        actionsLoading: false,
      }));
      return res;
    });
  };
  //delete Course Intake
  const deleteCourseIntake = async (id) => {
    //set loading
    setCourses((prev) => ({
      ...prev,
      actionsLoading: true,
    }));

    //soft delete item
    await API.delete(`/university/university-course/delete-intake/${id}`).then(
      (resp) => {
        if (!resp.error) {
          console.log("success");
        }
      }
    );

    setCourses((prev) => ({
      ...prev,
      actionsLoading: false,
    }));
  };
  const deleteCourseFee = async (id) => {
    //set loading
    setCourses((prev) => ({
      ...prev,
      actionsLoading: true,
    }));

    //soft delete item
    await API.delete(
      `/university/university-course/delete-tuition-fee/${id}`
    ).then((resp) => {
      if (!resp.error) {
        console.log("success");
      }
    });

    setCourses((prev) => ({
      ...prev,
      actionsLoading: false,
    }));
  };
  const deleteCourseExpense = async (id) => {
    //soft delete item
    await API.delete(
      `/university/university-course/delete-others-expense/${id}`
    ).then((resp) => {
      if (!resp.error) {
        console.log("success");
      }
    });
  };
  return {
    deleteOthersExpense,
    deleteCourseExpense,
    deleteCourseIntake,
    deleteCourseFee,
    updateIntakeInfo,
    findCourses,
    getStatusOverview,
    getCourseLevels,
    getheightEduLevels,
    getPrerequisites,
    getSpecializations,
    getDepartments,
    updateFeeInfo,
    getAdmissionSession,
    getSubSpecializations,
    rankingCompanies,
    getExams,
    getSingleCourse,
    clearBasicInfo,
    clearState,
    softDelete,
    hardDelete,
    itemsRestore,
    storeBasicInfo,
    updateBasicInfo,
    getCourseBasicInfo,
    getOtherExpenses,
    updateOthersExpnense,
    getRankingTypes,
    getRankingCompanies,
    getCourseRanking,
    createCourseRanking,
    updateCourseRanking,
    removeCourseRanking,
    storeMediaInfo,
    getMedia,
    setTopListed,
  };
}
